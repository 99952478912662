import {
  DELAY_BETWEEN_TWO_REEL,
  DELAY_BETWEEN_TWO_SYMBOL,
  FINISH_INSTANTLY_DELAY_BETWEEN_TWO_REEL,
  FINISH_INSTANTLY_DELAY_BETWEEN_TWO_SYMBOL,
  FINISH_INSTANTLY_GRAVITY_SYMBOL_GRAVITY,
  STORAGE_VERSION,
  SYMBOL_GRAVITY,
  SYMBOL_SELECTION_TIME,
  SYMBOL_WIND,
  TURBO_SPIN_SYMBOL_GRAVITY,
} from './defaults';

export interface IGameSettings {
  animation: IAnimationSettings;
}

interface IAnimationSettings {
  symbolAnimation: {
    gravity: number;
    turboSpinGravity: number;
    finishInstantlyGravity: number;
    wind: number;
    delayBetweenTwoSymbol: number;
    finishInstantDelayBetweenTwoSymbol: number;
    delayBetweenTwoReel: number;
    finishInstantDelayBetweenTwoReel: number;
  };
}

export class SettingsManager {
  private _animation!: IAnimationSettings;

  get animation(): IAnimationSettings {
    return this._animation;
  }

  set animation(value: IAnimationSettings) {
    this._animation = value;
    this.save();
  }

  save() {
    localStorage.setItem('settingsManager', JSON.stringify({ animation: this._animation }));
  }

  load(newSettings: IGameSettings) {
    this._animation = newSettings.animation;
    this.save();
  }

  getJSON(): IGameSettings {
    return { animation: this._animation };
  }

  reset() {
    localStorage.removeItem('settingsManager');
    localStorage.setItem('storageVersion', STORAGE_VERSION);
    this.init();
  }

  init() {
    const storageVersion = localStorage.getItem('storageVersion');
    const localSettingsString = localStorage.getItem('settingsManager');
    let localSettings;
    if (localSettingsString) {
      try {
        localSettings = JSON.parse(localSettingsString);
      }
      catch (error) {}
    }
    if (storageVersion !== STORAGE_VERSION) {
      this.reset();
    }
    this.animation = {
      symbolAnimation: {
        gravity: localSettings?.animation?.symbolAnimation?.gravity ?? SYMBOL_GRAVITY,
        turboSpinGravity:
          localSettings?.animation?.symbolAnimation?.turboSpinGravity ?? TURBO_SPIN_SYMBOL_GRAVITY,
        wind: localSettings?.animation?.symbolAnimation?.wind ?? SYMBOL_WIND,
        delayBetweenTwoSymbol:
          localSettings?.animation?.symbolAnimation?.delayBetweenTwoSymbol
          ?? DELAY_BETWEEN_TWO_SYMBOL,
        finishInstantDelayBetweenTwoSymbol:
          localSettings?.animation?.symbolAnimation?.finishInstantDelayBetweenTwoSymbol
          ?? FINISH_INSTANTLY_DELAY_BETWEEN_TWO_SYMBOL,
        finishInstantlyGravity:
          localSettings?.animation?.symbolAnimation?.finishInstantlyGravity
          ?? FINISH_INSTANTLY_GRAVITY_SYMBOL_GRAVITY,
        delayBetweenTwoReel:
          localSettings?.animation?.symbolAnimation?.delayBetweenTwoReel ?? DELAY_BETWEEN_TWO_REEL,
        finishInstantDelayBetweenTwoReel:
          localSettings?.animation?.symbolAnimation?.finishInstantDelayBetweenTwoReel
          ?? FINISH_INSTANTLY_DELAY_BETWEEN_TWO_REEL,
      },
    };

    if (!localSettings) {
      this.save();
    }
  }

  constructor() {
    this.init();
  }
}
