import { Sprite, Texture } from 'pixi.js';
import GameController from '.';
import Button from '../button';
import {
  LANDSCAPE_ADD_BET_BUTTON_CONTENT_X,
  LANDSCAPE_ADD_BET_BUTTON_CONTENT_Y,
  LANDSCAPE_ADD_BET_BUTTON_HEIGHT,
  LANDSCAPE_ADD_BET_BUTTON_WIDTH,
  LANDSCAPE_ADD_BET_BUTTON_X,
  LANDSCAPE_ADD_BET_BUTTON_Y,
  LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_X,
  LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_Y,
  LANDSCAPE_AUTOPLAY_BUTTON_HEIGHT,
  LANDSCAPE_AUTOPLAY_BUTTON_WIDTH,
  LANDSCAPE_AUTOPLAY_BUTTON_X,
  LANDSCAPE_AUTOPLAY_BUTTON_Y,
  LANDSCAPE_INFO_BUTTON_CONTENT_X,
  LANDSCAPE_INFO_BUTTON_CONTENT_Y,
  LANDSCAPE_INFO_BUTTON_X,
  LANDSCAPE_INFO_BUTTON_Y,
  LANDSCAPE_SETTINGS_BUTTON_X,
  LANDSCAPE_SETTINGS_BUTTON_Y,
  LANDSCAPE_SOUND_BUTTON_X,
  LANDSCAPE_SOUND_BUTTON_Y,
  LANDSCAPE_SPIN_BUTTON_CONTENT_HEIGHT,
  LANDSCAPE_SPIN_BUTTON_CONTENT_WIDTH,
  LANDSCAPE_SPIN_BUTTON_CONTENT_X,
  LANDSCAPE_SPIN_BUTTON_CONTENT_Y,
  LANDSCAPE_SPIN_BUTTON_HEIGHT,
  LANDSCAPE_SPIN_BUTTON_WIDTH,
  LANDSCAPE_SPIN_BUTTON_X,
  LANDSCAPE_SPIN_BUTTON_Y,
  LANDSCAPE_SPIN_CONTAINER_HEIGHT,
  LANDSCAPE_SPIN_CONTAINER_WIDTH,
  LANDSCAPE_SPIN_CONTAINER_X,
  LANDSCAPE_SPIN_CONTAINER_Y,
  LANDSCAPE_STAKE_BUTTON_CONTENT_X,
  LANDSCAPE_STAKE_BUTTON_CONTENT_Y,
  LANDSCAPE_STAKE_BUTTON_HEIGHT,
  LANDSCAPE_STAKE_BUTTON_SCALE,
  LANDSCAPE_STAKE_BUTTON_WIDTH,
  LANDSCAPE_STAKE_BUTTON_X,
  LANDSCAPE_STAKE_BUTTON_Y,
  LANDSCAPE_SUB_BET_BUTTON_CONTENT_X,
  LANDSCAPE_SUB_BET_BUTTON_CONTENT_Y,
  LANDSCAPE_SUB_BET_BUTTON_HEIGHT,
  LANDSCAPE_SUB_BET_BUTTON_WIDTH,
  LANDSCAPE_SUB_BET_BUTTON_X,
  LANDSCAPE_SUB_BET_BUTTON_Y,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_HEIGHT,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_WIDTH,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_X,
  LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_Y,
  LANDSCAPE_TURBO_SPIN_BUTTON_HEIGHT,
  LANDSCAPE_TURBO_SPIN_BUTTON_WIDTH,
  LANDSCAPE_TURBO_SPIN_BUTTON_X,
  LANDSCAPE_TURBO_SPIN_BUTTON_Y,
  SMALL_BUTTON_HEIGHT,
  SMALL_BUTTON_WIDTH,
} from './constants';
import TextButton from '../textButton';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';
import { ANTEBET_MULTIPLIER } from '../../resources/constants';
import Popup from '../popup';
import { splitSentence } from '../../../../utils/text';
import { getLocale } from '../../../../../localization';
import { fontSafeString } from '../../resources/fonts/fonts';

export const mountCommonButtons = (controller: GameController) => {
  controller.subBetButton = new Button(controller.blockContainer, {
    backgroundTexture: 'leftButton',
    disabledBackgroundTexture: 'disabledLeftButton',
    texture: '-',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      const currentIndex =
        controller.game.config.limits.legalBets.indexOf(controller.game.stake) || 0;
      controller.game.stake =
        controller.game.config.limits.legalBets[currentIndex > 0 ? currentIndex - 1 : 0];
    },
    backgroundPosition: {
      x: LANDSCAPE_ADD_BET_BUTTON_X,
      y: LANDSCAPE_ADD_BET_BUTTON_Y,
      width: LANDSCAPE_ADD_BET_BUTTON_WIDTH,
      height: LANDSCAPE_ADD_BET_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_ADD_BET_BUTTON_CONTENT_X,
      y: LANDSCAPE_ADD_BET_BUTTON_CONTENT_Y,
    },
  });
  controller.addBetButton = new Button(controller.blockContainer, {
    backgroundTexture: 'rightButton',
    disabledBackgroundTexture: 'disabledRightButton',
    texture: '+',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      const currentIndex =
        controller.game.config.limits.legalBets.indexOf(controller.game.stake) || 0;
      controller.game.stake =
        controller.game.config.limits.legalBets[
          Math.min(currentIndex + 1, controller.game.config.limits.legalBets.length - 1)
        ];
    },
    backgroundPosition: {
      x: LANDSCAPE_SUB_BET_BUTTON_X,
      y: LANDSCAPE_SUB_BET_BUTTON_Y,
      width: LANDSCAPE_SUB_BET_BUTTON_WIDTH,
      height: LANDSCAPE_SUB_BET_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_SUB_BET_BUTTON_CONTENT_X,
      y: LANDSCAPE_SUB_BET_BUTTON_CONTENT_Y,
    },
  });

  controller.spinContainer = new Sprite(Texture.from('spinContainer'));
  controller.blockContainer.addChild(controller.spinContainer);
  controller.spinContainer.x = LANDSCAPE_SPIN_CONTAINER_X;
  controller.spinContainer.y = LANDSCAPE_SPIN_CONTAINER_Y;
  controller.spinContainer.width = LANDSCAPE_SPIN_CONTAINER_WIDTH;
  controller.spinContainer.height = LANDSCAPE_SPIN_CONTAINER_HEIGHT;

  controller.turboSpinButton = new Button(controller.blockContainer, {
    backgroundTexture: 'turboSpinBackground',
    disabledBackgroundTexture: 'disabledTurboSpinButton',
    texture: controller.game.turboSpinActive ? 'turboSpinActive' : 'turboSpinPassive',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.turboSpinActive = !controller.game.turboSpinActive;
      controller.turboSpinButton.content.texture = Texture.from(
        controller.game.turboSpinActive ? 'turboSpinActive' : 'turboSpinPassive'
      );
    },
    backgroundPosition: {
      x: LANDSCAPE_TURBO_SPIN_BUTTON_X,
      y: LANDSCAPE_TURBO_SPIN_BUTTON_Y,
      width: LANDSCAPE_TURBO_SPIN_BUTTON_WIDTH,
      height: LANDSCAPE_TURBO_SPIN_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_X,
      y: LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_Y,
      width: LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_WIDTH,
      height: LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_HEIGHT,
    },
  });
  controller.stakeButton = new Button(controller.blockContainer, {
    backgroundTexture: 'betAmountBackground',
    text: '',
    fontSize: 48,
    onClick: () => {
      controller.game.onClick.triggerEvent();
    },
    backgroundPosition: {
      x: LANDSCAPE_STAKE_BUTTON_X,
      y: LANDSCAPE_STAKE_BUTTON_Y,
      width: LANDSCAPE_STAKE_BUTTON_WIDTH,
      height: LANDSCAPE_STAKE_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_STAKE_BUTTON_CONTENT_X,
      y: LANDSCAPE_STAKE_BUTTON_CONTENT_Y,
    },
  });
  controller.stakeButton.disabled = true;

  controller.spinButton = new Button(controller.blockContainer, {
    backgroundTexture: 'spinBackground',
    texture: 'spin',
    // disableShadow: false,
    onClick: () => {
      controller.game.onClick.triggerEvent();
      if (controller.game.betButtonsDisabler.disabled) {
        controller.game.finishInstantlyActive = true;
      } else {
        controller.game.runReels();
      }
    },
    backgroundPosition: {
      x: LANDSCAPE_SPIN_BUTTON_X,
      y: LANDSCAPE_SPIN_BUTTON_Y,
      width: LANDSCAPE_SPIN_BUTTON_WIDTH,
      height: LANDSCAPE_SPIN_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_SPIN_BUTTON_CONTENT_X,
      y: LANDSCAPE_SPIN_BUTTON_CONTENT_Y,
      width: LANDSCAPE_SPIN_BUTTON_CONTENT_WIDTH,
      height: LANDSCAPE_SPIN_BUTTON_CONTENT_HEIGHT,
    },
  });
  window.game?.onSpacePressed.addEventListener(() => {
    if (!window.game?.bigWinManager!.isActive) controller.spinButton.doClickEffect();
  });
  window.game?.onGamePaused.addEventListener(() => {
    controller.turboSpinButton.disabled = true;
  });
  window.game?.onGameUnPaused.addEventListener(() => {
    controller.turboSpinButton.disabled = false;
  });

  controller.autoPlayButton = new Button(controller.blockContainer, {
    backgroundTexture: 'autoSpin',
    disabledBackgroundTexture: 'autoSpinDisabled',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      if (controller.game.autoPlayCount === 0) {
        controller.game.autoPlayCount = -1;
      } else {
        controller.game.autoPlayCount = 0;
      }
    },
    backgroundPosition: {
      x: LANDSCAPE_AUTOPLAY_BUTTON_X,
      y: LANDSCAPE_AUTOPLAY_BUTTON_Y,
      width: LANDSCAPE_AUTOPLAY_BUTTON_WIDTH,
      height: LANDSCAPE_AUTOPLAY_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_X,
      y: LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_Y,
    },
  });
  controller.game.onAutoplayChange.addEventListener((e, value) => {
    if (value.autoPlayCount === 0) {
      controller.autoPlayButton.background.texture = Texture.from('autoSpin');
      if (controller.game.betButtonsDisabler.disabled) controller.autoPlayButton.disabled = true;
    } else {
      controller.autoPlayButton.background.texture = Texture.from('autoSpinOn');
    }
  });

  controller.goldenBetButton = new TextButton(controller.textButtonsContainer, {
    disabledOpacity: 0.6,
    background: {
      texture: 'anteBetBuyBonusButton',
      position: {
        x: 260 / 2,
        y: 114,
        width: 230,
        height: 200,
      },
    },
    content: {
      position: {
        x: 0,
        y: 130,
      },
      texture: 'anteBetOffBackground',
      scale: 1.5,
    },
    texts: [
      {
        text: getLocale(
          controller.game.anteBetActive ? 'slots.ui.common.on' : 'slots.ui.common.off'
        ).toLocaleUpperCase(),
        position: { x: 0, y: 130 },
        fontSize: controller.game.anteBetActive ? 50 : 36,
        fontFamily: 'ManchoBold',
      },
      {
        fontSize: 50,
        text: getLocale('slots.ui.common.bet').toLocaleUpperCase(),
        fontFamily: 'ManchoBold',
        position: { x: 0, y: -45 },
      },
      {
        fontSize: 50,
        text: formatAsCurrency(controller.game.stake * ANTEBET_MULTIPLIER),
        fontFamily: 'ManchoBold',
        color: 0xfe9b1c,
        position: { x: 0, y: 0 },
      },
      ...splitSentence(getLocale('slots.ui.dede.doubleChanceOfScatters'), 15).map((el, index) => ({
        fontSize: 30,
        text: el.toLocaleUpperCase(),
        fontFamily: 'ManchoBold',
        position: { x: 0, y: 45 + index * 30 },
      })),
    ],
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.anteBetActive = !controller.game.anteBetActive;
    },
  });

  controller.game.onAnteBetChange.addEventListener((_, value) => {
    if (value) {
      controller.goldenBetButton.setText(
        getLocale('slots.ui.common.on').toLocaleUpperCase(),
        0,
        50
      );
      controller.goldenBetButton.content.texture = Texture.from('anteBetOnBackground');
    } else {
      controller.goldenBetButton.setText(
        getLocale('slots.ui.common.off').toLocaleUpperCase(),
        0,
        36
      );
      controller.goldenBetButton.content.texture = Texture.from('anteBetOffBackground');
    }
  });

  controller.game.onStakeChange.addEventListener((_, value) => {
    controller.goldenBetButton.setText(formatAsCurrency(value * 1.25), 2);
  });

  controller.buyBonusButton = new TextButton(controller.textButtonsContainer, {
    disabledOpacity: 0.6,
    background: {
      texture: 'anteBetBuyBonusButton',
      position: {
        x: 260 / 2,
        y: 310,
        width: 230,
        height: 200,
        angle: 180,
      },
    },
    texts: [
      {
        text: 'BUY',
        position: { x: 0, y: -60 },
        fontFamily: 'ManchoBold',
        fontSize: 100,
      },
      {
        text: 'BONUS',
        position: { x: 0, y: 25 },
        fontFamily: 'ManchoBold',
        fontSize: 70,
      },
    ],
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.soundManager.soundEffectsTrack?.playBonusBuyOpen();

      const popup = new Popup(
        [
          ...splitSentence(getLocale('slots.ui.dede.doYouWantToBuyBonus'), 15).map((el) => ({
            label: el,
          })),
          { label: formatAsCurrency(controller.game.stake * 100), fontFamily: 'goldenTextFont' },
        ],
        {
          onOk: () => {
            controller.game.soundManager.soundEffectsTrack?.playBonusBuyConfirm();
            controller.game.runReels(true);
          },
          onCancel: () => {},
        }
      );
      popup.mount();
    },
  });
};
