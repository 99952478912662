import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import BgMusicTrack from './bgMusicTrack';
import SoundEffectsTrack from './soundEffectsTrack';
import BigWinTrack from './bigWinTrack';
import CachedSettingsManager from '../../../../cachedSettingsManager';
import { sound } from '@pixi/sound';
import { Ticker } from 'pixi.js';

type TSoundControlState = {
  soundEffectsEnabled: boolean;
  musicEnabled: boolean;
  gameSoundEnabled: boolean;
};

CachedSettingsManager.registerSetting('soundControlState', {
  soundEffectsEnabled: false,
  musicEnabled: false,
  gameSoundEnabled: false,
});

class SoundManager extends SoundTrack {
  private _bigWinTrack!: BigWinTrack;
  private _bgMusicTrack!: BgMusicTrack;
  private _soundEffectsTrack?: SoundEffectsTrack;

  mount() {
    this.volume = 1;

    this._bgMusicTrack = new BgMusicTrack('bgMusicTrack');
    this.registerTrack('bgMusicTrack', this._bgMusicTrack);
    this._bgMusicTrack?.mount();
    this._soundEffectsTrack = new SoundEffectsTrack('soundEffectsTrack');
    this.registerTrack('soundEffectsTrack', this._soundEffectsTrack);
    this._soundEffectsTrack?.mount();
    this._bigWinTrack = new BigWinTrack('bigWinTrack');
    this.registerTrack('bigWinTrack', this._bigWinTrack);
    this._bigWinTrack.mount(this._bgMusicTrack, this._soundEffectsTrack);

    // CachedSettingsManager.addListener('isSoundEnabled', (isSoundEnabled) => {
    //   this.volume = isSoundEnabled ? 1 : 0;
    // });

    this._bgMusicTrack?.startGame();

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.handleHide();
      } else {
        this.handleFocus();
      }
    });

    document.addEventListener('pagehide', () => {
      this.handleHide();
    });

    document.addEventListener('pageshow', () => {
      this.handleFocus();
    });

    this._isMounted = true;
  }

  get bgMusicTrack() {
    return this._bgMusicTrack;
  }

  get soundEffectsTrack() {
    return this._soundEffectsTrack;
  }

  get bigWinTrack() {
    return this._bigWinTrack;
  }

  resumeSounds = () => {
    if (sound.context && sound.context.audioContext.state === 'suspended') {
      sound.context.audioContext
        .resume()
        .then(() => {
          console.log('Pixi Sound resumed successfully');
        })
        .catch((e) => {
          console.error('Error resuming Pixi Sound:', e);
        });
    }

    sound.resumeAll();
  };

  handleHide = () => {
    document.removeEventListener('touchstart', this.resumeSounds);
    document.removeEventListener('click', this.resumeSounds);
    document.addEventListener('touchstart', this.resumeSounds);
    document.addEventListener('click', this.resumeSounds);
    Ticker.shared.stop();
    sound.pauseAll();
  };

  handleFocus = () => {
    Ticker.shared.start();
    sound.resumeAll();
    this.resumeSounds();
  };
}

export default SoundManager;

export { type TSoundControlState };
