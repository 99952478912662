import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import SoundBase from '../../../../game/managers/soundManager/soundBase';
import BgMusicTrack from './bgMusicTrack';
import SoundEffectsTrack from './soundEffectsTrack';
import CachedSettingsManager from '../../../../cachedSettingsManager';

class BigWinTrack extends SoundTrack {
  private _bigWinMusicTypePlaying: SoundBase | undefined = undefined;
  private _bigWinMusicType!: 'big' | 'mega' | 'olympus';
  private _bgMusicTrack?: BgMusicTrack;
  private _soundEffectsTrack?: SoundEffectsTrack;

  mount(bgMusicTrack: BgMusicTrack, soundEffectsTrack: SoundEffectsTrack) {
    this.registerMusic('musBigWin');
    this.registerMusic('musBigWinEnd');
    this.registerMusic('musMegaWin');
    this.registerMusic('musMegaWinEnd');
    this.registerMusic('musOlympusWin');
    this.registerMusic('musOlympusWinEnd');

    this._bgMusicTrack = bgMusicTrack;
    this._soundEffectsTrack = soundEffectsTrack;

    CachedSettingsManager.addListener('soundControlState', (value) => {
      const {
        musicEnabled,
        gameSoundEnabled,
      } = value as unknown as { musicEnabled: boolean; gameSoundEnabled: boolean };
      console.log('BigWinTrack2', musicEnabled, gameSoundEnabled);
      this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
    });

    const { musicEnabled, gameSoundEnabled } = CachedSettingsManager.get('soundControlState') as unknown as {
      musicEnabled: boolean;
      gameSoundEnabled: boolean;
    };
    console.log('BigWinTrack', musicEnabled, gameSoundEnabled);
    this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;

    this._isMounted = true;
  }

  private _getBigWinMusic(type: 'big' | 'mega' | 'olympus') {
    return this.getSound(
      `mus${type.substring(0, 1).toUpperCase() + type.substring(1)}Win`
    ) as SoundBase;
  }

  private _getBigWinMusicEnd(type: 'big' | 'mega' | 'olympus') {
    return this.getSound(
      `mus${type.substring(0, 1).toUpperCase() + type.substring(1)}WinEnd`
    ) as SoundBase;
  }

  playBigWin(type: 'big' | 'mega' | 'olympus') {
    if (this._bigWinMusicTypePlaying) this._bigWinMusicTypePlaying.stop();
    console.log('big win start play');
    const bigWinMusic = this._getBigWinMusic(type);

    this._bigWinMusicTypePlaying = bigWinMusic;
    this._bigWinMusicType = type;

    this._bgMusicTrack?.duckForSound(bigWinMusic);
    this._soundEffectsTrack?.duckForSound(bigWinMusic);

    bigWinMusic.play();

    const { musicEnabled, gameSoundEnabled } = CachedSettingsManager.get('soundControlState') as unknown as {
      musicEnabled: boolean;
      gameSoundEnabled: boolean;
    };

    this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
  }

  transitionToBigWinEnd() {
    if (!this._bigWinMusicTypePlaying || !this._bigWinMusicType) return;
    console.log('big win end play');

    const type = this._bigWinMusicType;
    const bigWinMusic = this._getBigWinMusic(type);
    const bigWinMusicEnd = this._getBigWinMusicEnd(type);

    this._bgMusicTrack?.duckForSound(bigWinMusicEnd);
    this._soundEffectsTrack?.duckForSound(bigWinMusicEnd);

    this.crossFade([bigWinMusic], bigWinMusicEnd, { stopAndRestoreVolume: true, duration: 1500 });
    bigWinMusicEnd.play();

    const { musicEnabled, gameSoundEnabled } = CachedSettingsManager.get('soundControlState') as unknown as {
      musicEnabled: boolean;
      gameSoundEnabled: boolean;
    };

    this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
  }

  getBigWinEndDuration(winType: 'big' | 'mega' | 'olympus') {
    return this._getBigWinMusicEnd(winType).duration;
  }

  getBigWinDuration(winType: 'big' | 'mega' | 'olympus') {
    return this._getBigWinMusic(winType).duration;
  }
}

export default BigWinTrack;
