import { Table } from 'antd';
import { IGameHistoryItem, IGameHistoryResponse } from '../../lib/games/dede/service/types';
import { useEffect, useRef, useState } from 'react';
import { Game } from '../../lib/games/game';
import { getGameHistory } from '../../lib/games/dede/service';
import { formatAsCurrency } from '../../lib/game/managers/currencyManager';
import styles from './index.module.css';

const GameHistory: React.FC<{ height: number; game: Game }> = ({ height, game }) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [gameHistoryData, setGameHistoryData] = useState<IGameHistoryResponse>();
  const [gameHistoryItems, setGameHistoryItems] = useState<IGameHistoryItem[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const getHistoryData = async () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      const data = await getGameHistory(game, page, pageSize);
      setGameHistoryData(data);
      const _gameHistoryItems: IGameHistoryItem[] = [];
      _gameHistoryItems.splice(pageSize * (page - 1), pageSize, ...data.rows);
      setGameHistoryItems(_gameHistoryItems);
    }, 50);
  };

  useEffect(() => {
    if (game?.id) getHistoryData();
  }, []);

  useEffect(() => {
    if (game?.id) getHistoryData();
  }, [pageSize, page]);

  return (
    <div
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        overflow: 'auto',
        marginLeft: -15,
        marginRight: -15,
      }}
    >
      <Table
        size='small'
        columns={[
          {
            title: <div className={styles.historyHeader}>ID</div>,
            dataIndex: 'betId',
            width: 40,
            key: 'betId',
            render: (text) => <div style={{ fontSize: 8 }}>{text}</div>,
          },
          {
            title: <div className={styles.historyHeader}>Date</div>,
            dataIndex: 'transactionDate',
            width: 40,
            key: 'transactionDate',
            render: (text) => {
              return <div style={{ fontSize: 8 }}>{new Date(text).toLocaleString()}</div>;
            },
          },
          {
            title: <div className={styles.historyHeader}>Bet</div>,
            dataIndex: 'amount',
            width: 40,
            key: 'amount',
            render: (text) => <div style={{ fontSize: 10 }}>{formatAsCurrency(text)}</div>,
          },
          {
            title: <div className={styles.historyHeader}>Win</div>,
            dataIndex: 'creditAmount',
            width: 40,
            key: 'creditAmount',
            render: (text) => <div style={{ fontSize: 10 }}>{formatAsCurrency(text)}</div>,
          },
          {
            title: <div className={styles.historyHeader}>Jackpot</div>,
            width: 60,
            dataIndex: 'jackpotAmount',
            key: 'jackpotAmount',
            render: (text) => <div style={{ fontSize: 10 }}>{formatAsCurrency(text)}</div>,
          },
          {
            title: <div className={styles.historyHeader}>Result</div>,
            dataIndex: 'betResult',
            width: 40,
            key: 'betResult',
            render: (text) => <div style={{ fontSize: 10 }}>{text}</div>,
          },
        ]}
        dataSource={gameHistoryData?.rows || []}
        pagination={{
          current: page,
          pageSize: pageSize,
          total: gameHistoryData?.totalCount,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
        scroll={{ y: height - 120 }}
      />
    </div>
  );
};

export default GameHistory;
