import { Spine } from '@pixi/spine-pixi';
import { Game } from '../../../games/game';
import { Container } from 'pixi.js';

export class CoinFountainManager {
  spine!: Spine;
  zIndex = 9999;
  container!: Container;

  constructor(private game: Game) {
    this.container = game.app.stage;
  }

  handleResize = () => {
    if (!this.spine) return;
    this.spine.x = this.game.width / 2;
    this.spine.y = this.game.height / 2;
    this.spine.scale.set(this.game.scale * 0.7);
  };

  stop() {
    if (!this.spine) return;
    this.spine.visible = false;
    this.spine.destroy();
    this.game.onResize.removeEventListener(this.handleResize);
  }

  animate(container?: Container, zIndex = 9999) {
    this.container = container || this.container;
    this.zIndex = zIndex;
    if (!this.spine) {
      this.spine = Spine.from({
        skeleton: `coinFountainData`,
        atlas: `coinFountainAtlas`,
      });
      this.spine.zIndex = this.zIndex;
      this.spine.x = this.game.width / 2;
      this.spine.y = this.game.height / 2;
      this.container.addChild(this.spine);
      this.handleResize();
      this.game.onResize.addEventListener(this.handleResize);
    }

    this.spine.visible = true;
    const transitionAnimation = this.spine.state.setAnimation(0, 'coin_fontain', true);

    const animationTime = (transitionAnimation.animation?.duration || 1.1) * 1000;
    return animationTime;
  }
}
