import SoundTrack from '../../../../game/managers/soundManager/soundTrack';

class JackpotGrandTrack extends SoundTrack {
  mount() {
    this.registerMusic('jackpotWinGrand', { loop: true, maxVolume: 0.5 });
    this.registerMusic('musJackpotOutro', { maxVolume: 0.5 });

    this._isMounted = true;
  }

  play() {
    const main = this.getSound('jackpotWinGrand')!;
    main.play();
  }

  stop() {
    const outro = this.getSound('musJackpotOutro')!;
    const main = this.getSound('jackpotWinGrand')!;

    this.crossFade([main], outro, { stopAndRestoreVolume: true });
    outro.play();
    outro.onComplete(() => this._handleComplete());
  }
}

export default JackpotGrandTrack;
