import { BitmapText, Container, Graphics, Sprite, Texture, Ticker } from 'pixi.js';
import Reel from '../../models/reel';
import delay from 'delay';
import { Dede } from '../../index';
import {
  DELAY_AFTER_SELECTION,
  LANDSCAPE_LOGO_OFFSET_X,
  LANDSCAPE_LOGO_OFFSET_Y,
  PORTRAIT_LOGO_OFFSET_X,
  PORTRAIT_LOGO_OFFSET_Y,
  REELS_BACKGROUND_OFFSET_X,
  REELS_BACKGROUND_OFFSET_Y,
  REELS_CONTAINER_OFFSET_X,
  REELS_CONTAINER_OFFSET_Y,
  REELS_COUNT,
  REELS_MASK_OFFSET,
  REELS_ROOF_OFFSET_X,
  REELS_ROOF_OFFSET_Y,
  SEPARATORS_CONTAINER_OFFSET_X,
  SEPARATORS_CONTAINER_OFFSET_Y,
  SHORT_DIMENSION,
  SYMBOL_GAP,
  SYMBOL_HEIGHT,
  SYMBOL_SHAKE_DURATION,
  SYMBOL_SHAKE_MAGNITUDE,
  SYMBOL_WIDTH,
  SYMBOLS_CONTAINER_OFFSET_X,
  SYMBOLS_CONTAINER_OFFSET_Y,
  SYMBOLS_PER_REEL,
} from '../../resources/constants';
import { IGameOutcome } from '../../service/types';
import { Game } from '../../../game';
import { hardCopy } from '../../../../utils/hardcopy';
import GameEvent from '../../../gameEvent';
import { formatAsCurrency } from '../../../../game/managers/currencyManager';
import { getLocalCoordsFor } from '../../../../game/math/layoutUtils';
import { fontSafeString } from '../../resources/fonts/fonts';
import {
  simpleAnimatePropertiesTo,
  simpleAnimationDuration,
} from '../../../../game/managers/animationManager';
import { getDualAxisLength } from '../../../../game/math/mathUtils';

export class ReelsManager {
  public reels: Reel[] = [];
  private destroyedReelsCount = 0;
  private fallCompleteTimeout?: NodeJS.Timeout;
  private randomNumbers: number[][] = [];
  container = new Container();
  separatorContainer = new Graphics();
  symbolsContainer = new Sprite();
  mask = new Graphics();
  clickArea = new Graphics();
  backgroundSprite = new Sprite();
  containerSprite = new Sprite();
  containerCoverSprite = new Sprite();
  roofSprite = new Sprite();
  reelSeparators: Sprite[] = [];
  logoSprite = new Sprite();
  winAmountTexts: { text: BitmapText; value: number; outcome: IGameOutcome }[] = [];
  startPositionY = 0;
  endPositionY = 0;
  _scale = 1;
  // This triggers when all currently moving reels stop moving and all their symbols have finished their stop animation.
  // This can happen more than once if symbols explode.
  private _onAllReelsStopped = new GameEvent('onAllReelsStopped');
  private _reelsFallingCount = 0;
  // This triggers when all newly dropped symbols have finished 'presenting' themselves (showboating).
  // This can include things like animations, flares and particle trails.
  private _onAllReelsPresented = new GameEvent('onAllReelsPresented');
  private _presentingReelsCount = 0;
  private _multiplierTexts: { text: BitmapText; value: number }[] = [];
  private _currentSpinUid = 0;

  get onAllReelsStopped() {
    return this._onAllReelsStopped;
  }

  get scale() {
    return this._scale;
  }

  constructor(
    private game: Dede,
    private events: {
      onDestroy: () => void;
      onFallComplete: (multiplier: number, outcome?: IGameOutcome) => void;
    }
  ) {
    // this.symbolsContainer.rect(0, 0, 100, 100);

    this.container.addChild(this.symbolsContainer);
    this.container.alpha = 0.98;
    this.container.zIndex = 2;

    this._onAllReelsPresented.addEventListener(() => {
      this.handleFallComplete();
    });

    this.game.onSpin.addEventListener((_, { spinUid }) => {
      this._currentSpinUid = spinUid;
      this._multiplierTexts = [];
      this.game.winHistoryManager.clearTumbleWinTexts();
    });
  }

  registerMultiplierText(text: BitmapText, value: number, symbolSpinUid: number) {
    if (symbolSpinUid === this._currentSpinUid) this._multiplierTexts.push({ text, value });
  }

  loadNumbers(incomingNumbers: number[][], clearPrevious: boolean, winningSymbols?: number[]) {
    if (this.randomNumbers.length === 0) {
      this.randomNumbers = incomingNumbers;
    } else if (clearPrevious) {
      this.randomNumbers.forEach((reelNumbers, i) => {
        reelNumbers.push(...incomingNumbers[i]);
      });
    } else {
      incomingNumbers.forEach((reelNumbers, i) => {
        const newSymbols = reelNumbers.slice(
          0,
          reelNumbers.length -
            this.reels[i].symbols.filter((symbol) => !winningSymbols!.includes(symbol.symbolType))
              .length
        );
        this.randomNumbers[i].push(...newSymbols);
      });
    }
  }

  processOutcome(clearPrevious: boolean, winningSymbols?: number[]) {
    if (!clearPrevious) this.game.prevOutcome = this.game.currentOutcome;
    else this.game.prevOutcome = undefined;
    this.game.currentOutcome = this.game.outcomes.shift();
    if (this.game.currentOutcome) {
      this.game.onBeforeSymbolsDrop.triggerEvent({ outcome: this.game.currentOutcome });
      this.loadNumbers(this.game.currentOutcome.symbols, clearPrevious, winningSymbols);
    }
  }

  createReelsMask(withBigOffset = true) {
    const { height, width, x, y } = this.backgroundSprite;
    this.mask?.destroy?.();

    this.mask = new Graphics();
    const offset = -REELS_MASK_OFFSET;
    this.mask.rect(
      x - offset,
      y - offset - (withBigOffset ? 0 : 33),
      width + offset * 2,
      height + offset * 2
    );

    this.mask.fill('transparent');
    this.container.addChild(this.mask);
    this.symbolsContainer.mask = this.mask;
    this.symbolsContainer.alpha = 0.99;
  }

  handleResize = () => {
    this._scale = 1;
    const orientation = this.game.getOrientation();

    if (orientation === 'portrait') {
      if (this.game.width < SHORT_DIMENSION) {
        this._scale = (this._scale * this.game.width) / SHORT_DIMENSION;
      }
    } else {
      if (this.game.height < SHORT_DIMENSION) {
        this._scale = (this._scale * this.game.height) / SHORT_DIMENSION;
      }
    }
    this.container.scale.set(this._scale);

    const scaledContainerWidth = this.container.width / this._scale;
    const scaledContainerHeight = this.container.height / this._scale;

    this.startPositionY = -100;
    this.endPositionY =
      this.startPositionY + (SYMBOL_HEIGHT + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1) + 190;

    this.backgroundSprite.x = (scaledContainerWidth - this.backgroundSprite.width) / 2;
    this.backgroundSprite.y = REELS_BACKGROUND_OFFSET_Y;
    this.backgroundSprite.alpha = 0.99;

    this.symbolsContainer.zIndex = 1;
    this.symbolsContainer.x = SYMBOLS_CONTAINER_OFFSET_X;
    this.symbolsContainer.y = SYMBOLS_CONTAINER_OFFSET_Y;

    this.containerSprite.x = REELS_CONTAINER_OFFSET_X;
    this.containerSprite.y = REELS_CONTAINER_OFFSET_Y;
    this.containerSprite.scale.set(1.26);

    this.containerCoverSprite.x = REELS_CONTAINER_OFFSET_X;
    this.containerCoverSprite.y = REELS_CONTAINER_OFFSET_Y;
    this.containerCoverSprite.scale.set(1.26);

    // create clickable area with same size as backgroundSprite
    this.clickArea.clear();
    this.clickArea.beginFill(0x000000, 0);
    this.clickArea.drawRect(
      this.backgroundSprite.x,
      this.backgroundSprite.y,
      this.backgroundSprite.width,
      this.backgroundSprite.height
    );
    this.clickArea.endFill();
    this.clickArea.zIndex = 3;
    this.clickArea.interactive = true;
    this.clickArea.cursor = 'pointer';
    this.clickArea.on('pointerdown', () => {
      if (!this.game.bigWinManager.isActive && !this.game.gameDisabler.disabled) {
        if (this.game.betButtonsDisabler.disabled) this.game.finishInstantlyActive = true;
      }
    });

    if (orientation === 'portrait') {
      // this.logoSprite.x = (scaledContainerWidth - this.logoSprite.width) / 2;
      // this.logoSprite.y = PORTRAIT_LOGO_OFFSET_Y;
      this.logoSprite.visible = false;
    } else {
      // this.logoSprite.x = LANDSCAPE_LOGO_OFFSET_X;
      // this.logoSprite.y = LANDSCAPE_LOGO_OFFSET_Y;
      this.logoSprite.visible = false;
    }
    this.createReelsMask();
    this.container.x = (this.game.width - this.container.width) / 2;
    this.container.y = (this.game.height - this.container.height) / 2 + 600 * this.scale;

    this.roofSprite.x = (this.game.width - this.roofSprite.width) / 2;
    this.roofSprite.y = this.container.y - 190 * this.scale;
    this.roofSprite.scale.set(1.4 * this.scale);
  };

  async mount() {
    this.backgroundSprite = new Sprite(Texture.from('reelBackground'));
    this.containerSprite = new Sprite(Texture.from('reelContainer'));
    this.containerCoverSprite = new Sprite(Texture.from('FSReelsBackgroundCoverImage'));
    this.containerCoverSprite.visible = false;
    this.roofSprite = new Sprite(Texture.from('reelRoof'));
    this.roofSprite.zIndex = 2;
    this.reelSeparators = new Array(REELS_COUNT - 1).fill(0).map((_, i) => {
      const separator = new Sprite(Texture.from('reelSeparator'));
      separator.x = i * (SYMBOL_WIDTH + SYMBOL_GAP) + SYMBOL_WIDTH;
      this.separatorContainer.addChild(separator);
      return separator;
    });
    this.separatorContainer.x = SEPARATORS_CONTAINER_OFFSET_X;
    this.separatorContainer.y = SEPARATORS_CONTAINER_OFFSET_Y;
    this.logoSprite = new Sprite(Texture.from('logo'));
    this.logoSprite.zIndex = 2;

    // this.game.freeSpinManager.onFreeSpinTransition.addEventListener((event, isActive) => {
    //   if (isActive) {
    //     this.containerSprite.texture = Texture.from('fsReelContainer');
    //   } else {
    //     this.containerSprite.texture = Texture.from('reelContainer');
    //   }
    // });

    this.container.addChild(this.clickArea);
    this.container.addChild(this.mask);
    this.container.addChild(this.backgroundSprite);
    this.container.addChild(this.containerSprite);
    this.container.addChild(this.containerCoverSprite);
    this.game.app.stage.addChild(this.roofSprite);
    this.container.addChild(this.logoSprite);
    this.container.addChild(this.separatorContainer);
    this.game.app.stage.addChild(this.container);

    this.handleResize();
    this.handleResize();
    this.game.onResize.addEventListener(this.handleResize);

    this.reels = new Array(REELS_COUNT).fill(0).map((_, i) => {
      const reel = new Reel(
        this.game,
        this.symbolsContainer,
        i,
        SYMBOLS_PER_REEL,
        this.startPositionY,
        this.randomNumbers[i],
        {
          onDestroy: this.handleDestroy,
          onFallComplete: () => {},
          onExplodeComplete: this.handleExplodeComplete,
        },
        this._onAllReelsStopped
      );

      reel.onReelStopComplete.addEventListener(() => {
        this._reelsFallingCount--;
        if (this._reelsFallingCount === 0) {
          this._onAllReelsStopped.triggerEvent();
        }
      });

      reel.onReelPresentationComplete.addEventListener(() => {
        this._presentingReelsCount--;
        if (this._presentingReelsCount === 0) {
          this._onAllReelsPresented.triggerEvent();
        }
      });

      return reel;
    });

    this._spinReels();
    this.createReelsMask(true);

    this.game.onNewSymbolsComing.addEventListener(() => {
      this.createReelsMask(true);
    });

    this.game.onOldSymbolsDropping.addEventListener(() => {
      this.createReelsMask(false);
    });
  }

  mountFreeSpinFrame() {
    this.containerCoverSprite.visible = true;
  }

  mountMainFrame() {
    this.containerCoverSprite.visible = false;
  }

  update(ticker: Ticker) {
    this.reels.forEach((el) => el.update(ticker));
  }

  destroyReels = async () => {
    this.game.onOldSymbolsDropping.triggerEvent();
    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].destroy();
      await delay(
        this.game.finishInstantlyActive
          ? Game.settings.animation.symbolAnimation.finishInstantDelayBetweenTwoReel
          : Game.settings.animation.symbolAnimation.delayBetweenTwoReel
      );
    }
  };

  async runReels(buyFreeSpins: boolean = false) {
    if (buyFreeSpins) {
      this.game.freeSpinActivating = true;
      this.game.betButtonsDisabler.disable('buyBonus');
    }

    this.destroyReels();
    await this.game.serviceManager.getSpin(buyFreeSpins);
    this.processOutcome(true);
    this.game.pendingSpinResponse = false;
  }

  async runReelsWithOutcomes(outcomes: IGameOutcome[]) {
    this.game.outcomes = outcomes;
    this.processOutcome(true);
    await this.destroyReels();
  }

  animateScatterWin() {
    this.reels.forEach((reel) => {
      reel.animateScatterWin();
    });
  }

  private handleExplodeComplete = async () => {
    const allCompleted = this.reels.every(
      (reel) => reel.explodedSymbols.length === reel.explodingSymbols.length
    );

    this.reels.forEach((reel) => {
      reel.symbols.forEach((symbol) => {
        symbol.fadeIn();
      });
    });

    if (!allCompleted) return;

    this.clearWinAmountTexts();

    for (const reel of this.reels) {
      this._reelsFallingCount++;
      if (reel.explodingSymbols.length) this._presentingReelsCount++;
      reel.moveAfterExplode(5 * reel.index);
    }
  };

  private handleFallComplete = async () => {
    const allCompleted = this.reels.every((reel) => !reel.moving);

    if (!allCompleted) return;

    this.game.onGameUnPaused.removeEventListener(this.handleFallComplete);

    if (this.fallCompleteTimeout) clearTimeout(this.fallCompleteTimeout);
    this.printWinAmount(this.game.currentOutcome);
    const handleGameUnpaused = () => {
      this.fallCompleteTimeout = setTimeout(async () => {
        this.game.onBeforeFallComplete.triggerEvent();
        if (this.game.paused) {
          this.game.onGameUnPaused.addEventListener(handleGameUnpaused);
          return;
        } else {
          this.game.onGamePaused.removeEventListener(handleGameUnpaused);
        }

        const winnings = this.game.currentOutcome?.winnings?.filter(
          (winning) => winning.winningSymbol !== this.game.config.scatterSymbol
        );
        const multiplier = this.game.currentOutcome?.multiplier || 0;
        if (winnings?.length) {
          this.game.tumble(this.game.currentOutcome!);

          this.reels.forEach((reel) => {
            reel.explodingSymbols = [];
            reel.explodedSymbols = [];
          });

          this.game.onSymbolSelection.triggerEvent();
          setTimeout(() => {
            this.game.onExplode.triggerEvent();
          }, DELAY_AFTER_SELECTION / (this.game.turboSpinActive ? 3 : 2));

          const winningSymbols = winnings.map((winning) => winning.winningSymbol);
          winnings.forEach((winning) => {
            this.reels.forEach((reel) => {
              reel.symbols.forEach(async (symbol) => {
                if (symbol.symbolType === Number(winning.winningSymbol)) {
                  reel.explodingSymbols.push(symbol);
                  symbol.select();
                  symbol.shake(SYMBOL_SHAKE_MAGNITUDE, SYMBOL_SHAKE_DURATION);
                  await delay(DELAY_AFTER_SELECTION / (this.game.turboSpinActive ? 3 : 1));
                  symbol.explode();
                }
              });
            });
          });

          // fade other symbols
          this.reels.forEach((reel) => {
            reel.symbols.forEach(async (symbol) => {
              if (!winningSymbols.includes(symbol.symbolType)) {
                symbol.fadeOut();
              }
            });
          });
          this.events.onFallComplete(multiplier, this.game.currentOutcome);
          this.processOutcome(false, winningSymbols);
        } else {
          const isMultiplierAnimationStarted = await this.game.winHistoryManager.flyMultiplierTexts(
            this._multiplierTexts,
            this.game.currentOutcome!
          );
          if (isMultiplierAnimationStarted)
            await new Promise((resolve) => setTimeout(resolve, this.game.isQuickMode ? 100 : 300));

          this.events.onFallComplete(multiplier, this.game.currentOutcome);
        }
      }, 1);
    };
    handleGameUnpaused();
  };

  private _spinReels = () => {
    const delayTime = this.game.finishInstantlyActive
      ? Game.settings.animation.symbolAnimation.finishInstantDelayBetweenTwoReel
      : Game.settings.animation.symbolAnimation.delayBetweenTwoReel;

    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].start(delayTime * i, this._currentSpinUid);
      this._reelsFallingCount++;
      this._presentingReelsCount++;
    }
  };

  private handleDestroy = async () => {
    this.destroyedReelsCount++;
    if (this.destroyedReelsCount === REELS_COUNT) {
      this.destroyedReelsCount = 0;
      this.symbolsContainer.removeChildren();

      const startNextSpin = async () => {
        this.game.onNewSymbolsComing.triggerEvent();
        this._spinReels();
        this.events.onDestroy();
        this.game.onPendingSpinResponseChange.removeEventListener(startNextSpin);
      };
      if (!this.game.pendingSpinResponse) await startNextSpin();
      else {
        this.game.onPendingSpinResponseChange.addEventListener(startNextSpin);
      }
    }
  };

  printWinAmount(outcome?: IGameOutcome) {
    const middleSymbolPositions: { reelIndex: number; index: number }[] = [];
    outcome?.winnings.forEach((win) => {
      if (!win.winAmount) return;
      const reelsFound = this.reels
        .filter((reel) => reel.symbols.some((s) => s.symbolType === win.winningSymbol))
        .filter((reel) => {
          const symbolsFound = reel.symbols.filter((s) => s.symbolType === win.winningSymbol);
          if (symbolsFound.length > 1) return true;
          if (
            middleSymbolPositions.some(
              (position) =>
                (position.reelIndex === reel.index + 1 || position.reelIndex === reel.index - 1) &&
                position.index === symbolsFound[0].index
            )
          )
            return false;
          return true;
        });
      const middleReel = reelsFound[Math.floor(reelsFound.length / 2)];
      const symbolsFound = middleReel.symbols
        .filter((s) => s.symbolType === win.winningSymbol)
        .filter(
          (s) =>
            !middleSymbolPositions.some(
              (position) =>
                (position.reelIndex === middleReel.index + 1 ||
                  position.reelIndex === middleReel.index - 1) &&
                position.index === s.index
            )
        );

      const middleSymbol = symbolsFound[Math.floor(symbolsFound.length / 2)];
      if (!middleSymbol) {
        console.log('middleSymbol not found', middleSymbolPositions, outcome);
        return;
      }
      middleSymbolPositions.push({
        reelIndex: middleReel.index,
        index: middleSymbol.index,
      });
      const coords = getLocalCoordsFor(middleSymbol.spine).pos;
      const isScatter = middleSymbol.symbolType === this.game.config.scatterSymbol;
      const text = new BitmapText({
        text: fontSafeString('symbolOverlayFont', formatAsCurrency(win.winAmount)),
        style: {
          fontSize: 60 * this._scale,
          fontFamily: 'symbolOverlayFont',
        },
        x: coords[0] + (isScatter ? 25 : 0),
        y: coords[1] + (isScatter ? 20 : 0),
      });
      text.zIndex = 3;
      text.anchor.set(0.5);
      this.game.app.stage.addChild(text);
      this.winAmountTexts.push({ text, value: win.winAmount, outcome });
    });
  }

  async clearWinAmountTexts() {
    const promises: Promise<void>[] = [];
    console.log('clearWinAmountTexts', [...this.winAmountTexts]);

    this.winAmountTexts.forEach(({ text, value, outcome }) => {
      console.log('clearWinAmountTexts text', text.text, value, outcome);

      const dest = this.game.winHistoryManager.tumbleWinAmountFocalPoint;
      const dist = getDualAxisLength(text.x - dest[0], text.y - dest[1]);

      const doFast = this.game.turboSpinActive || this.game.finishInstantlyActive;

      promises.push(
        simpleAnimatePropertiesTo(
          dist / (doFast ? 4 : 2),
          text as unknown as { [p: string]: number },
          text as unknown as { [p: string]: number },
          {
            x: { endValue: dest[0] },
            y: { endValue: dest[1] },
          },
          { autoEndOnError: true }
        )
          .promise.then(async () => {
            this.game.winHistoryManager.updateTumbleWinValue(value);
            const startingScale = text.scale.x;

            await simpleAnimationDuration(
              doFast ? 50 : 100,
              ({ progress }: { progress: number }) => {
                text.alpha = 1 - progress;
                console.log('clearWinAmountTexts text->>', text, text.text, value, outcome);
                text.scale?.set(startingScale * progress * 0.5 + startingScale);
              }
            ).promise;
            // await simpleAnimatePropertiesTo(
            //   100,
            //   text as unknown as { [p: string]: number },
            //   text as unknown as { [p: string]: number },
            //   { alpha: { endValue: 0 } },
            //   { autoEndOnError: true },
            // ).promise;
          })
          .then(() => {
            text.parent?.removeChild(text);
            text.destroy();
          })
      );
    });

    await Promise.all(promises);

    this.winAmountTexts.forEach(({ text }) => {
      if (!text.destroyed) text.destroy();
    });

    this.winAmountTexts = [];
  }

  getDynamicBounds() {
    const x = this.symbolsContainer.x;
    const y = this.endPositionY - (SYMBOL_HEIGHT + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1);
    const width = (SYMBOL_WIDTH + SYMBOL_GAP) * REELS_COUNT - SYMBOL_GAP;
    const height = (SYMBOL_HEIGHT + SYMBOL_GAP) * SYMBOLS_PER_REEL - SYMBOL_GAP;
    return { x, y, width, height };
  }
}
