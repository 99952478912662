export const dedeAssets = [
  {
    alias: 'previewAtlas',
    src: '/assets/games/dede/features/preview/preview.atlas',
  },
  {
    alias: 'previewData',
    src: '/assets/games/dede/features/preview/preview.json',
  },
  { alias: 'featuresFrame', src: '/assets/games/dede/features/frame.webp' },
  {
    alias: 'featuresFreeSpin',
    src: '/assets/games/dede/features/free_spin.webp',
  },
  {
    alias: 'featuresHighlight',
    src: '/assets/games/dede/features/highlight_text.webp',
  },
  {
    alias: 'featuresMultipliers',
    src: '/assets/games/dede/features/multipliers.webp',
  },
  {
    alias: 'featuresBottomRibbon',
    src: '/assets/games/dede/features/bottomRibbon.webp',
  },
  {
    alias: 'featuresTopRibbon',
    src: '/assets/games/dede/features/ribbon.webp',
  },
  { alias: 'featuresTumble', src: '/assets/games/dede/features/tumble.webp' },
  { alias: 'tumbleWinBackground', src: '/assets/games/dede/tumble_win_win_container.png' },
  { alias: 'tumbleWinSeparator', src: '/assets/games/dede/Tumble_win_separator.png' },

  {
    alias: 'multiplierData',
    src: '/assets/games/dede/symbols/Multipliers/multi/multi_b.json',
  },
  {
    alias: 'multiplierAtlas',
    src: '/assets/games/dede/symbols/Multipliers/multi/multi_b.atlas',
  },

  {
    alias: 'symbolOverlayFont',
    src: '/assets/games/dede/fonts/symbolOverlayFont.xml',
  },
  {
    alias: 'goldenTextFont',
    src: '/assets/games/dede/fonts/goldenTextFont.xml',
  },
  { alias: 'okButton', src: '/assets/games/dede/okButton.webp' },
  { alias: 'cancelButton', src: '/assets/games/dede/cancelButton.webp' },

  {
    alias: 'characterData',
    src: '/assets/games/dede/character1/character_4.json',
  },
  {
    alias: 'characterAtlas',
    src: '/assets/games/dede/character1/character_4.atlas',
  },
  { alias: 'winData', src: '/assets/games/dede/outcomes/winAll.json' },
  { alias: 'winAtlas', src: '/assets/games/dede/outcomes/winAll.atlas' },
  { alias: 'blastData', src: '/assets/games/dede/explosion/explosion.json' },
  { alias: 'blastAtlas', src: '/assets/games/dede/explosion/explosion.atlas' },
  {
    alias: 'logoData',
    src: '/assets/games/dede/logo/destinyOfOlympus/logo(1).json',
  },
  {
    alias: 'logoAtlas',
    src: '/assets/games/dede/logo/destinyOfOlympus/logo(1).atlas',
  },
  {
    alias: 'slideBackground',
    src: '/assets/games/dede/slideButton/slider.webp',
  },
  { alias: 'slideBump', src: '/assets/games/dede/slideButton/bump.webp' },
  {
    alias: 'slideBumpDisabled',
    src: '/assets/games/dede/slideButton/grey-bump.webp',
  },

  { alias: '-', src: '/assets/games/dede/-.webp' },
  { alias: '+', src: '/assets/games/dede/+.webp' },
  { alias: 'autoPlay', src: '/assets/games/dede/autoPlay.webp' },
  { alias: 'leftButton', src: '/assets/games/dede/leftButton.webp' },
  { alias: 'rightButton', src: '/assets/games/dede/rightButton.webp' },
  {
    alias: 'disabledLeftButton',
    src: '/assets/games/dede/left_button_disabled.webp',
  },
  {
    alias: 'disabledRightButton',
    src: '/assets/games/dede/right_button_disabled_.webp',
  },
  {
    alias: 'disabledTurboSpinButton',
    src: '/assets/games/dede/disabled_turbo_spin.webp',
  },
  { alias: 'spinBackground', src: '/assets/games/dede/spinBackground.webp' },
  {
    alias: 'turboSpinBackground',
    src: '/assets/games/dede/turboSpinBackground.webp',
  },
  {
    alias: 'betAmountBackground',
    src: '/assets/games/dede/betAmountBackground.webp',
  },
  {
    alias: 'turboSpinPassive',
    src: '/assets/games/dede/turbo_spin_off.png',
  },
  { alias: 'turboSpinActive', src: '/assets/games/dede/turbo_spin_on.png' },
  { alias: 'spin', src: '/assets/games/dede/spin.webp' },
  { alias: 'spinDisabled', src: '/assets/games/dede/spin_disabled.webp' },
  { alias: 'spinContainer', src: '/assets/games/dede/spinContainer.webp' },
  { alias: 'buyBonus', src: '/assets/games/dede/buyBonus.webp' },
  { alias: 'goldenBet', src: '/assets/games/dede/goldenBet.webp' },
  { alias: 'infoButton', src: '/assets/games/dede/infoButton.webp' },
  {
    alias: 'winHistoryDivider',
    src: '/assets/games/dede/winHistoryDivider.webp',
  },
  {
    alias: 'freeSpinCounterBackground',
    src: '/assets/games/dede/fs_counter_bg.webp',
  },
  { alias: 's1', src: '/assets/games/dede/s1.webp' },
  { alias: 's2', src: '/assets/games/dede/s2.webp' },
  { alias: 's3', src: '/assets/games/dede/s3.webp' },
  { alias: 's4', src: '/assets/games/dede/s4.webp' },
  { alias: 's5', src: '/assets/games/dede/s5.webp' },
  { alias: 's6', src: '/assets/games/dede/s6.webp' },
  { alias: 's7', src: '/assets/games/dede/s7.webp' },
  { alias: 's8', src: '/assets/games/dede/s8.webp' },
  { alias: 's9', src: '/assets/games/dede/s9.webp' },
  { alias: 's13', src: '/assets/games/dede/s13.webp' },
  {
    alias: 'jackpotBackground',
    src: '/assets/games/dede/jackpot/background/jackpotBackground.jpg',
  },
  {
    alias: 'jackpotMetaData',
    src: '/assets/games/dede/jackpot/meta/meta_s.json',
  },
  {
    alias: 'jackpotMetaAtlas',
    src: '/assets/games/dede/jackpot/meta/meta_s.atlas',
  },

  { alias: 's1Data', src: '/assets/games/dede/symbols/low/low1/low_1.json' },
  { alias: 's1Atlas', src: '/assets/games/dede/symbols/low/low1/low_1.atlas' },
  { alias: 's2Data', src: '/assets/games/dede/symbols/low/low2/low_2.json' },
  { alias: 's2Atlas', src: '/assets/games/dede/symbols/low/low2/low_2.atlas' },
  { alias: 's3Data', src: '/assets/games/dede/symbols/low/low3/low_3.json' },
  { alias: 's3Atlas', src: '/assets/games/dede/symbols/low/low3/low_3.atlas' },
  { alias: 's4Data', src: '/assets/games/dede/symbols/low/low4/low_4.json' },
  { alias: 's4Atlas', src: '/assets/games/dede/symbols/low/low4/low_4.atlas' },
  { alias: 's5Data', src: '/assets/games/dede/symbols/low/low5/low_5.json' },
  { alias: 's5Atlas', src: '/assets/games/dede/symbols/low/low5/low_5.atlas' },
  { alias: 's6Data', src: '/assets/games/dede/symbols/high/high1/high_1.json' },
  {
    alias: 's6Atlas',
    src: '/assets/games/dede/symbols/high/high1/high_1.atlas',
  },
  { alias: 's7Data', src: '/assets/games/dede/symbols/high/high2/high_2.json' },
  {
    alias: 's7Atlas',
    src: '/assets/games/dede/symbols/high/high2/high_2.atlas',
  },
  { alias: 's8Data', src: '/assets/games/dede/symbols/high/high3/high_3.json' },
  {
    alias: 's8Atlas',
    src: '/assets/games/dede/symbols/high/high3/high_3.atlas',
  },
  { alias: 's9Data', src: '/assets/games/dede/symbols/high/high4/high_4.json' },
  {
    alias: 's9Atlas',
    src: '/assets/games/dede/symbols/high/high4/high_4.atlas',
  },
  {
    alias: 's10Data',
    src: '/assets/games/dede/symbols/scatterLast/scatter.json',
  },
  {
    alias: 's10Atlas',
    src: '/assets/games/dede/symbols/scatterLast/scatter.atlas',
  },

  { alias: 'glowingParticle1', src: '/assets/games/dede/glowingParticle2.png' },

  { alias: 'logo', src: '/assets/games/dede/logo.webp' },
  {
    alias: 'FSReelsBackgroundCoverImage',
    src: '/assets/games/dede/reels/FSBackgroundCoverImage.png',
  },
  { alias: 'reelContainer', src: '/assets/games/dede/reels/background.webp' },
  { alias: 'reelRoof', src: '/assets/games/dede/reels/roof.webp' },
  { alias: 'startButton', src: '/assets/games/dede/start_button.png' },
  { alias: 'symbolSelect', src: '/assets/games/dede/payline.webp' },
  { alias: 'jpGrand', src: '/assets/games/dede/grand.webp' },
  { alias: 'jpMajor', src: '/assets/games/dede/major.webp' },
  { alias: 'jpMini', src: '/assets/games/dede/mini.webp' },
  { alias: 'jpMinor', src: '/assets/games/dede/minor.webp' },
  {
    alias: 'anteBetBuyBonusButton',
    src: '/assets/games/dede/banner_buy_bonus_ante_bet.png',
  },
  {
    alias: 'historyContainer',
    src: '/assets/games/dede/banner_tumble_history.png',
  },
  {
    alias: 'reelBackground',
    src: '/assets/games/dede/reels/symbolsBackground.webp',
  },
  { alias: 'reelSeparator', src: '/assets/games/dede/reelSeparator.webp' },
  {
    alias: 'bottomTextGradient',
    src: '/assets/games/dede/bottomTextGradient.webp',
  },
  { alias: 'anteBetOffBackground', src: '/assets/games/dede/ante_bet_off.png' },
  { alias: 'anteBetOnBackground', src: '/assets/games/dede/ante_bet_on.png' },
  { alias: 'config', src: '/assets/games/dede/config.webp' },
  { alias: 'sound', src: '/assets/games/dede/sound.webp' },
  { alias: 'soundDisabled', src: '/assets/games/dede/soundDisabled.webp' },
  { alias: 'info', src: '/assets/games/dede/info.webp' },
  { alias: 'autoSpin', src: '/assets/games/dede/autoSpin.webp' },
  { alias: 'freeSpin', src: '/assets/games/dede/re_spin.png' },
  {
    alias: 'autoSpinDisabled',
    src: '/assets/games/dede/autoSpinDisabled.webp',
  },
  { alias: 'autoSpinOn', src: '/assets/games/dede/autoSpinOn.webp' },
  { alias: 'soundDisabled', src: '/assets/games/dede/soundDisabled.webp' },
  { alias: 'paylineData', src: '/assets/games/dede/payline/payline.json' },
  { alias: 'paylineAtlas', src: '/assets/games/dede/payline/payline.atlas' },
  {
    alias: 'backgroundAtlas',
    src: '/assets/games/dede/background/main_background_d.atlas',
  },
  {
    alias: 'backgroundData',
    src: '/assets/games/dede/background/main_background_d.json',
  },
  {
    alias: 'backgroundImage',
    src: '/assets/games/dede/background/backround.jpg',
  },
  {
    alias: 'FSBackgroundImage',
    src: '/assets/games/dede/background/FS_backround.jpg',
  },
  { alias: 'popupAtlas', src: '/assets/games/dede/popup/notification.atlas' },
  { alias: 'popupData', src: '/assets/games/dede/popup/notification.json' },

  {
    alias: 'musFreeSpinsIntro',
    src: '/assets/games/dede/sound/mfx_freespins_intro.m4a',
    loadLazy: true,
  },
  {
    alias: 'musFreeSpins',
    src: '/assets/games/dede/sound/mus_freespins.m4a',
    loadLazy: true,
  },
  {
    alias: 'musFreeSpinsOutro',
    src: '/assets/games/dede/sound/mfx_freespins_outro.m4a',
    loadLazy: true,
  },
  {
    alias: 'musBaseGameMain',
    src: '/assets/games/dede/sound/mus_basegame_main.m4a',
    loadLazy: true,
  },
  {
    alias: 'musIntro',
    src: '/assets/games/dede/sound/mfx_main_intro.m4a',
    loadLazy: true,
  },
  {
    alias: 'musBaseGameWinMode',
    src: '/assets/games/dede/sound/mus_basegame_winMode.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxSymExplode',
    src: '/assets/games/dede/sound/sfx_sym_explode.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxSymLand',
    src: '/assets/games/dede/sound/sfx_sym_land.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxSymHighlight',
    src: '/assets/games/dede/sound/sfx_sym_highlight.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxScatterLand',
    src: '/assets/games/dede/sound/sfx_scatter_land.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxScatterWin',
    src: '/assets/games/dede/sound/sfx_scatter_win.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxMultiplierLand',
    src: '/assets/games/dede/sound/sfx_multi_land.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxMultiplierReveal',
    src: '/assets/games/dede/sound/sfx_multi_reveal.m4a',
    loadLazy: true,
  },
  {
    alias: 'musTumble',
    src: '/assets/games/dede/sound/sfx_sym_tumble_2.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxBonusBuyOpen',
    src: '/assets/games/dede/sound/sfx_bonus_buy.m4a',
    loadLazy: true,
  },
  {
    alias: 'sfxBonusBuyConfirm',
    src: '/assets/games/dede/sound/sfx_bonus_confirm.m4a',
    oadLazy: true,
  },
  {
    alias: 'sfxClick',
    src: '/assets/games/dede/sound/sfx_buttonClick.m4a',
    loadLazy: true,
  },
  {
    alias: 'musBigWin',
    src: '/assets/games/dede/sound/bigWin/mfx_bigwin.m4a',
    loadLazy: true,
  },
  {
    alias: 'musBigWinEnd',
    src: '/assets/games/dede/sound/bigWin/mfx_bigwin_end.m4a',
    loadLazy: true,
  },
  {
    alias: 'musMegaWin',
    src: '/assets/games/dede/sound/bigWin/mfx_megawin.m4a',
    loadLazy: true,
  },
  {
    alias: 'musMegaWinEnd',
    src: '/assets/games/dede/sound/bigWin/mfx_megawin_end.m4a',
    loadLazy: true,
  },
  {
    alias: 'musOlympusWin',
    src: '/assets/games/dede/sound/bigWin/mfx_olympicwin.m4a',
    loadLazy: true,
  },
  {
    alias: 'musOlympusWinEnd',
    src: '/assets/games/dede/sound/bigWin/mfx_olympicwin_end.m4a',
    loadLazy: true,
  },

  {
    alias: 'musJackpotOutro',
    src: '/assets/games/dede/sound/jackpot/mfx_outro.m4a',
    loadLazy: true,
  },
  {
    alias: 'jackpotWinMinor',
    src: '/assets/games/dede/sound/jackpot/mfx_win_minor.m4a',
    loadLazy: true,
  },
  {
    alias: 'jackpotWinMini',
    src: '/assets/games/dede/sound/jackpot/mfx_win_mini.m4a',
    loadLazy: true,
  },
  {
    alias: 'jackpotWinMajor',
    src: '/assets/games/dede/sound/jackpot/mfx_win_major.m4a',
    loadLazy: true,
  },
  {
    alias: 'jackpotWinGrand',
    src: '/assets/games/dede/sound/jackpot/mfx_win_grand.m4a',
    loadLazy: true,
  },
  {
    alias: 'jackpotMetaLand1',
    src: '/assets/games/dede/sound/jackpot/sfx_metaSym_land_1.m4a',
    loadLazy: true,
  },
  {
    alias: 'jackpotMetaLand2',
    src: '/assets/games/dede/sound/jackpot/sfx_metaSym_land_2.m4a',
    loadLazy: true,
  },
  {
    alias: 'jackpotMetaLand3',
    src: '/assets/games/dede/sound/jackpot/sfx_metaSym_land_3.m4a',
    loadLazy: true,
  },
  {
    alias: 'jackpotTrigger',
    src: '/assets/games/dede/sound/jackpot/sfx_jackpot_trigger.m4a',
    loadLazy: true,
  },

  {
    alias: 'jackpotNewData',
    src: '/assets/games/dede/jackpot/jackpotNew/vault.json',
    loadLazy: true,
  },
  {
    alias: 'jackpotNewAtlas',
    src: '/assets/games/dede/jackpot/jackpotNew/vault.atlas',
    loadLazy: true,
  },
  {
    alias: 'coinFountainData',
    src: '/assets/games/dede/coinFountain/coin_fontain.json',
    loadLazy: true,
  },
  {
    alias: 'coinFountainAtlas',
    src: '/assets/games/dede/coinFountain/coin_fontain.atlas',
    loadLazy: true,
  },
  {
    alias: 'jackpotTransitionData',
    src: '/assets/games/dede/transition/transition.json',
    loadLazy: true,
  },
  {
    alias: 'jackpotTransitionAtlas',
    src: '/assets/games/dede/transition/transition.atlas',
    loadLazy: true,
  },
  {
    alias: 'jackpot',
    src: '/assets/games/dede/jackpot.png',
    loadLazy: true,
  },
];
