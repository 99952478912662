import Axios from 'axios';
import {
  IGameHistoryResponse,
  IGameInitResponse,
  IGameSpinResponse,
  IJackpotBalance,
} from './types';
import { IGameStat } from '../../types';
import { Game } from '../../game';

const PROD_URL = 'https://slots-engine.sandbox.accamax.com';
const SANDBOX_URL = 'https://slots-engine.sandbox.accamax.com';
const DEMO_URL = 'https://slots-engine.demo.accamax.com';

const prepareBody = (token: string) => {
  const queryParams = getQueryParams();
  const subdomain = window.location.hostname.split('.')[1]; // Extracts "sandbox"
  const path = window.location.pathname; // "/[platform]"
  const isLocalhost = window.location.hostname.toLowerCase() === 'localhost';
  const isPreview = window.location.hostname.includes('pages.dev');

  const isDemo =
    queryParams['demo']?.toLowerCase() === 'true' ||
    subdomain?.toLowerCase() === 'demo';

  const platform = isDemo
    ? 'demo'
    : path.split('/').filter(Boolean).pop()?.toLowerCase(); // "[platform]

  const isSandbox =
    isPreview || isLocalhost || subdomain?.toLowerCase() === 'sandbox';

  const FINAL_URL = isDemo ? DEMO_URL : isSandbox ? SANDBOX_URL : PROD_URL;
  const demoString = isDemo ? 'true' : 'false';

  switch (platform) {
    case 'prn': {
      return {
        finalUrl: FINAL_URL,
        data: {
          platformCode: platform || subdomain?.toLowerCase() || 'demo',
          userCode: queryParams['customer'] || 'gg',
          brandCode: queryParams['trader'] || 'tst1',
          channel: queryParams['platform'] || 'm',
          currency: queryParams['currency'] || 'usd',
          gameCode: queryParams['gameid'] || '00001',
          country: queryParams['country'] || 'tr',
          lang: queryParams['lang'] || 'en',
          lobby: queryParams['lobby'] || 'http://lobby',
          demo: demoString,
          token: queryParams['token'] || token,
        },
      };
    }
    default: {
      return {
        finalUrl: FINAL_URL,
        data: {
          platformCode: platform || subdomain?.toLowerCase() || 'demo',
          userCode: queryParams['customer'] || 'gg',
          brandCode: queryParams['trader'] || 'tst1',
          channel: queryParams['platform'] || 'm',
          currency: queryParams['currency'] || 'usd',
          gameCode: queryParams['gameid'] || '00001',
          country: queryParams['country'] || 'tr',
          lang: queryParams['lang'] || 'en',
          lobby: queryParams['lobby'] || 'http://lobby',
          demo: demoString,
          token: queryParams['token'] || token,
        },
      };
    }
  }
};

export const getGameInitData = async (token: string) => {
  // TODO: get baseurl from environment

  const dataPrep = prepareBody(token);
  const config = {
    headers: {
      'X-Token': dataPrep.data.token,
    },
  };
  const result = (
    await Axios.post(dataPrep.finalUrl + '/api/v1/Game/Init', dataPrep.data, config)
  ).data;
  return result.data as IGameInitResponse;
};

export const getGameHistory = async (game: Game, page = 1, pageSize = 10) => {
  const dataPrep: any = prepareBody(game.id);
  dataPrep.data.page = page;
  dataPrep.data.pageSize = pageSize;
  const config = {
    headers: {
      'X-Token': dataPrep.data.token,
    },
  };
  const result = (
    await Axios.post(
      dataPrep.finalUrl + '/api/v1/Game/BetHistory',
      dataPrep.data,
      config
    )
  ).data;
  return result.data as IGameHistoryResponse;
};

export const getGameStats = async (token: string) => {
  const dataPrep = prepareBody(token);
  const config = {
    headers: {
      'X-Token': dataPrep.data.token,
    },
  };
  const result = (
    await Axios.post(dataPrep.finalUrl + '/api/v1/Game/Ping', dataPrep.data, config)
  ).data;
  return result.data as {
    stats: IGameStat;
    majorJackpot: IJackpotBalance;
    grandJackpot: IJackpotBalance;
  };
};
export const getGameSpin = async (
  game: Game,
  buyFreeSpins: boolean = false
) => {
  const dataPrep = prepareBody(game.id);
  const config = {
    headers: {
      SymbolsTest:
        window.testData?.testSymbols &&
        JSON.stringify(window.testData?.testSymbols),
      JackpotsTest:
        window.testData?.jackpotTier &&
        JSON.stringify(window.testData?.jackpotTier),
      'X-Token': dataPrep.data.token,
    },
  };
  delete window.testData?.jackpotTier;

  const result = (
    await Axios.post(
      dataPrep.finalUrl + '/api/v1/Game/Spin',
      {
        ...dataPrep.data,
        stake: game.stake,
        buyFreeSpins,
        anteBetEnabled: game.anteBetActive,
      },
      config
    )
  ).data;
  return result.data as IGameSpinResponse;
};

function getQueryParams(): Record<string, string> {
  const params = new URLSearchParams(window.location.search);
  const queryParams: Record<string, string> = {};

  // Iterate over all parameters
  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}
