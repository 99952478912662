import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import MainMusicTrack from './mainMusicTrack';
import FreeSpinTrack from './freeSpinTrack';
import CachedSettingsManager from '../../../../cachedSettingsManager';
import JackpotMiniTrack from './jackpotMiniTrack';
import JackpotMinorTrack from './jackpotMinorTrack';
import JackpotMajorTrack from './jackpotMajorTrack';
import JackpotGrandTrack from './jackpotGrandTrack';
import { JackpotTier } from '../../JackpotManager';

class BGMusicTrack extends SoundTrack {
  private _mainMusicTrack?: MainMusicTrack;
  private _freeSpinTrack?: FreeSpinTrack;
  private _jackpotMiniTrack?: JackpotMiniTrack;
  private _jackpotMinorTrack?: JackpotMinorTrack;
  private _jackpotMajorTrack?: JackpotMajorTrack;
  private _jackpotGrandTrack?: JackpotGrandTrack;
  private _currentSong: 'main' | 'freeSpin' | 'jackpot' | 'winMode' = 'main';

  mount() {
    this._mainMusicTrack = new MainMusicTrack('mainMusicTrack');
    this._mainMusicTrack?.mount();
    this.registerTrack('mainMusicTrack', this._mainMusicTrack);
    this._freeSpinTrack = new FreeSpinTrack('freeSpinTrack');
    this._freeSpinTrack?.mount();
    this.registerTrack('freeSpinTrack', this._freeSpinTrack);

    this._jackpotMiniTrack = new JackpotMiniTrack('jackpotMiniTrack');
    this._jackpotMiniTrack?.mount();
    this.registerTrack('jackpotMiniTrack', this._jackpotMiniTrack);

    this._jackpotMinorTrack = new JackpotMinorTrack('jackpotMinorTrack');
    this._jackpotMinorTrack?.mount();
    this.registerTrack('jackpotMinorTrack', this._jackpotMinorTrack);

    this._jackpotMajorTrack = new JackpotMajorTrack('jackpotMajorTrack');
    this._jackpotMajorTrack?.mount();
    this.registerTrack('jackpotMajorTrack', this._jackpotMajorTrack);

    this._jackpotGrandTrack = new JackpotGrandTrack('jackpotGrandTrack');
    this._jackpotGrandTrack?.mount();
    this.registerTrack('jackpotGrandTrack', this._jackpotGrandTrack);

    this.registerMusic('musBaseGameWinMode', { loop: true, maxVolume: 0.5 });

    this._isMounted = true;

    CachedSettingsManager.addListener('soundControlState', (value) => {
      const { musicEnabled, gameSoundEnabled } = value as unknown as {
        musicEnabled: boolean;
        gameSoundEnabled: boolean;
      };
      this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
    });

    const { musicEnabled, gameSoundEnabled } = CachedSettingsManager.get(
      'soundControlState'
    ) as unknown as {
      musicEnabled: boolean;
      gameSoundEnabled: boolean;
    };
    this.volume = musicEnabled && gameSoundEnabled ? 1 : 0;
  }

  startGame() {
    this._mainMusicTrack?.playFromIntro();
    this._currentSong = 'main';
  }

  playMainMusic(transitionFromFreeSpin = false) {
    if (
      this._currentSong === 'main' ||
      (this._currentSong === 'freeSpin' && !transitionFromFreeSpin)
    )
      return;

    this._mainMusicTrack?.play();
    this._currentSong = 'main';

    this.crossFade([this.getSound('musBaseGameWinMode')!], this.getSound('mainMusicTrack')!, {
      stopAndRestoreVolume: true,
      duration: 1500,
    });
  }

  playWinModeMusic() {
    if (this._currentSong !== 'main') return;

    this._currentSong = 'winMode';
    const winModeSong = this.getSound('musBaseGameWinMode')!;
    winModeSong.play();

    this.crossFade([this.getSound('mainMusicTrack')!], winModeSong, { stopAndRestoreVolume: true });
  }

  playFreeSpinMusic() {
    if (this._currentSong === 'freeSpin') return;

    this._currentSong = 'freeSpin';
    this._freeSpinTrack?.play();

    this.crossFade(
      [this.getSound('musBaseGameWinMode')!, this.getSound('mainMusicTrack')!],
      this.getSound('freeSpinTrack')!,
      { stopAndRestoreVolume: true }
    );
  }

  playJackpotMusic(tier: JackpotTier) {
    if (this._currentSong === 'jackpot') return;

    this._currentSong = 'jackpot';

    let trackName = '';

    if (tier === JackpotTier.mini) {
      this._jackpotMiniTrack?.play();
      trackName = 'jackpotMiniTrack';
    } else if (tier === JackpotTier.minor) {
      this._jackpotMinorTrack?.play();
      trackName = 'jackpotMinorTrack';
    } else if (tier === JackpotTier.major) {
      this._jackpotMajorTrack?.play();
      trackName = 'jackpotMajorTrack';
    } else if (tier === JackpotTier.grand) {
      this._jackpotGrandTrack?.play();
      trackName = 'jackpotGrandTrack';
    }

    this.crossFade([this.getSound('mainMusicTrack')!], this.getSound(trackName)!, {
      stopAndRestoreVolume: true,
    });
  }

  stopJackpotMusic() {
    if (this._currentSong !== 'jackpot') return;

    this._currentSong = 'main';

    this.crossFade(
      [
        this.getSound('jackpotMiniTrack')!,
        this.getSound('jackpotMinorTrack')!,
        this.getSound('jackpotMajorTrack')!,
        this.getSound('jackpotGrandTrack')!,
      ],
      this.getSound('mainMusicTrack')!,
      { stopAndRestoreVolume: true }
    );
  }

  stopFreeSpinMusic() {
    this._freeSpinTrack?.stop();
    this._freeSpinTrack?.onComplete(() => {
      this.playMainMusic(true);
    });
  }
}

export default BGMusicTrack;
