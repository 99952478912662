import { Point, Text } from 'pixi.js';
import { ColorGradientFilter, DropShadowFilter, GlowFilter, OutlineFilter } from 'pixi-filters';

const applyGoldenMoneyTextStyle = (
  element: Text,
  {
    useHeavyGlow = false,
  }: {
    useHeavyGlow?: boolean;
  } = {},
) => {
  const outlineFilter = new OutlineFilter({ thickness: 3, color: 0xdb7e0b });

  // const dropShadowFilter = new DropShadowFilter({
  //   offset: new Point(0, 3),
  //   blur: 1,
  //   alpha: 0.8,
  //   quality: 5,
  // });

  const glowFilter = new GlowFilter({
    distance: 3,
    innerStrength: 1,
    color: 0xffffff,
    alpha: 0.15,
  });

  const colorGradientFilter = new ColorGradientFilter({
    type: 0,
    angle: 180,
    replace: true,
    stops: [
      { offset: 0, color: 0xffffff, alpha: 1 },
      { offset: 0.1, color: 0xffffff, alpha: 1 },
      { offset: 0.3, color: 0xffbe39, alpha: 1 },
      { offset: 0.64, color: 0xd6810f, alpha: 1 },
      { offset: 0.9, color: 0xf4b857, alpha: 1 },
      { offset: 1, color: 0xd6810f, alpha: 1 },
    ].map(({ offset, color, alpha }) => {
      return { offset: (offset - 0.5) * 0.5 + 0.5, color: color, alpha };
    }),
  });

  // const heavyGlowFilter = new GlowFilter({
  //   distance: 20,
  //   outerStrength: 10,
  //   color: 0x004080,
  //   alpha: 0.8,
  // });
  const heavyGlowFilter = new GlowFilter({
    distance: 25,
    outerStrength: 15,
    color: 0x002040,
    alpha: 0.9,
  });

  element.filters = [
    colorGradientFilter,
    glowFilter,
    outlineFilter,
    // useHeavyGlow ? heavyGlowFilter : dropShadowFilter,
    heavyGlowFilter,
    ...(!element.filters
      ? []
      : Array.isArray(element.filters)
        ? element.filters
        : [element.filters]),
  ];

  element.style = {
    ...element.style,
    letterSpacing: 7,
    fontFamily: 'ManchoBold',
  };
};

export { applyGoldenMoneyTextStyle };
