import { Container, Graphics, Texture, Ticker } from 'pixi.js';
import {
  LOW_SYMBOL_SCALE,
  IS_MULTIPLIER_SYMBOL_TYPE,
  HIGH_SYMBOL_SCALE,
  MULTIPLIER_SYMBOL_SCALE,
  SCATTER_SYMBOL_SCALE,
  SCATTER_WIDTH,
  SHOW_BORDERS,
  SYMBOL_GAP,
  SYMBOL_HEIGHT,
  SYMBOL_WIDTH,
  SYMBOL_X_OFFSET,
  SYMBOL_Y_OFFSET,
  SYMBOLS_PER_REEL,
} from '../../resources/constants';
import Symbol from '../symbol';
import { IReelEvents } from './types';
import { Dede } from '../..';
import { Game } from '../../../game';
import { Spine } from '@pixi/spine-pixi';
import GameEvent from '../../../gameEvent';
import delay from 'delay';

export default class Reel {
  symbols: Symbol[] = [];
  slotTextures: Texture[] = [];
  moving = false;
  explodingSymbols: Symbol[] = [];
  explodedSymbols: Symbol[] = [];
  completeTimeout: NodeJS.Timeout | null = null;
  private _hasProcessedLandSound = false;
  private _onAllReelsStopped!: GameEvent;
  private _fallingSymbolsCount = 0;
  private _presentingSymbolsCount = 0;
  // This triggers after all the symbols in a reel have stopped moving and finished 'stopping'.  It also triggers if
  // there was an explosion but none of the exploded symbols were in this reel, to keep events uniform.
  private _onReelStopComplete = new GameEvent('onReelStopComplete');
  private _removeReelStopCompleteListener: (() => void) | null = null;
  // This triggers after all the symbols in a reel have finished 'presenting' themselves (showboating).
  private _onReelPresentationComplete = new GameEvent('onReelPresentationComplete');

  private _removeReelPresentationCompleteListener: (() => void) | null = null;
  private _spinUid = 0;

  constructor(
    public game: Dede,
    public container: Container,
    public index: number,
    private symbolCount: number,
    private startY: number,
    private randomNumbers: number[],
    private events: IReelEvents,
    onAllReelsStopped: GameEvent
  ) {
    this.symbols = [];

    // Load the textures

    this.slotTextures = [
      Texture.from('s1'),
      Texture.from('s2'),
      Texture.from('s3'),
      Texture.from('s4'),
      Texture.from('s5'),
      Texture.from('s6'),
      Texture.from('s7'),
      Texture.from('s8'),
      Texture.from('s9'),
      Texture.from('s10'),
    ];

    this._onAllReelsStopped = onAllReelsStopped;
  }

  get onReelStopComplete() {
    return this._onReelStopComplete;
  }

  get onReelPresentationComplete() {
    return this._onReelPresentationComplete;
  }

  addSymbol(index: number, moveAfterDelayTime: number, isJackpot = false, spinUid: number) {
    if (!this.container) return;
    const symbolType = this.randomNumbers.pop();
    if (symbolType === undefined) {
      console.log('symbolType is undefined', index);
      return;
    }
    const isScatter = symbolType === this.game.config.scatterSymbol;

    const symbolIndex = isScatter ? 10 : symbolType + 1;

    const spine =
      symbolType > 100000
        ? Spine.from({
            skeleton: 'multiplierData',
            atlas: 'multiplierAtlas',
          })
        : Spine.from({
            skeleton: 's' + symbolIndex + 'Data',
            atlas: 's' + symbolIndex + 'Atlas',
          });
    spine.state.setAnimation(0, 'idle', false);
    this.container.addChild(spine);

    if (symbolType === this.game.config.scatterSymbol) {
      spine.scale.set(SCATTER_SYMBOL_SCALE);
    } else if (symbolType < 5) spine.scale.set(LOW_SYMBOL_SCALE);
    else if (symbolType > 1000) spine.scale.set(MULTIPLIER_SYMBOL_SCALE);
    else spine.scale.set(HIGH_SYMBOL_SCALE);

    if (SHOW_BORDERS) {
      let border = new Graphics();
      border.rect(0, 0, spine.width, spine.height);
      spine.addChild(border);
    }

    spine.y = this.startY + (isScatter ? 125 : 0) - index * (SYMBOL_HEIGHT + SYMBOL_GAP);
    spine.zIndex = isScatter ? 2 : 1;

    const symbol = new Symbol(
      this,
      symbolType,
      spine,
      {
        start: {
          x:
            this.index * (SYMBOL_WIDTH + SYMBOL_GAP) +
            SYMBOL_X_OFFSET +
            (isScatter ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0),
          y: spine.y - ((isScatter && index) === 0 ? 80 : 0),
        },
        end: {
          y:
            this.game.reelsManager.endPositionY +
            SYMBOL_Y_OFFSET -
            (SYMBOL_HEIGHT + SYMBOL_GAP) * this.symbols.length +
            (isScatter ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0),
        },
      },

      {
        onDestroy: () => {
          this.container?.removeChild(spine);
          this.symbols = this.symbols.filter((s) => s !== symbol);
          if (this.symbols.length === 0) {
            this.events.onDestroy();
          }
        },
        onExplodeComplete: () => {
          if (this.explodedSymbols.find((el) => el.index === symbol.index)) return;
          this.explodedSymbols.push(symbol);
          if (this.explodedSymbols.length === this.explodingSymbols.length) {
            this.events.onExplodeComplete();
          }
          this._hasProcessedLandSound = false;
        },
        onFallComplete: () => {
          // This code executes on the first symbol to land in each reel after a spin or an explosion
          // It determines if the new symbols contain a scatter and plays the correct landing sound either way
          if (!this._hasProcessedLandSound) {
            this._hasProcessedLandSound = true;
            const newSymbolCount = this.explodedSymbols.length
              ? this.explodedSymbols.length
              : this.symbols.length;
            const newSymbols = this.symbols
              .slice(this.symbols.length - newSymbolCount)
              .map((symbol) => symbol.symbolType);
            const hasNewScatter = newSymbols.some(
              (symbol) => symbol === this.game.config.scatterSymbol
            );
            const hasMultiplier = newSymbols.some(IS_MULTIPLIER_SYMBOL_TYPE);

            if (hasMultiplier) this.game.soundManager.soundEffectsTrack?.playMultiplierLand();
            else if (hasNewScatter) this.game.soundManager.soundEffectsTrack?.playScatterLand();
            else this.game.soundManager.soundEffectsTrack?.playSymLand();
          }

          const allCompleted = this.symbols.every((symbol) => !symbol.falling);
          if (allCompleted) {
            if (this.completeTimeout) clearTimeout(this.completeTimeout);
            this.completeTimeout = setTimeout(() => {
              this.moving = false;
              this.events.onFallComplete();
            }, 0);
          }
        },
      },
      this.symbols.length,
      moveAfterDelayTime,
      isJackpot,
      this._onAllReelsStopped,
      spinUid
    );

    this._fallingSymbolsCount++;
    symbol.onSymbolStopComplete.addEventListener(() => {
      this._fallingSymbolsCount--;
      if (this._fallingSymbolsCount === 0) this._onReelStopComplete.triggerEvent();
    });

    this._presentingSymbolsCount++;

    symbol.onPresentationComplete.addEventListenerOnce(() => {
      this._presentingSymbolsCount--;
      if (this._presentingSymbolsCount === 0) this._onReelPresentationComplete.triggerEvent();
    });

    this.symbols.push(symbol);
  }

  async moveAfterExplode(delayTime: number) {
    this.moving = true;
    const unExplodedSymbols = this.symbols
      .filter((s) => this.explodedSymbols.indexOf(s) === -1)
      .sort((a, b) => a.index - b.index);
    this.explodingSymbols.forEach((el) => el.deselect());
    this.symbols = unExplodedSymbols;
    unExplodedSymbols.forEach((symbol, index) => {
      symbol.fallDown(
        this.game.reelsManager.endPositionY -
          (SYMBOL_HEIGHT + SYMBOL_GAP) * index +
          SYMBOL_Y_OFFSET +
          (symbol.symbolType === this.game.config.scatterSymbol
            ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2
            : 0)
      );
      symbol.index = index;
      this._fallingSymbolsCount++;
    });

    for (let i = unExplodedSymbols.length; i < this.symbolCount; i++) {
      this.addSymbol(i, delayTime, undefined, this._spinUid);
    }

    if (!unExplodedSymbols.length)
      // We need to trigger this since it will not be detected from any falling symbols and the reelsManager is going
      // to expect this event once from each reel.
      this._onReelStopComplete.triggerEvent();
  }

  destroy() {
    this.symbols.forEach((symbol) => {
      symbol.destroy();
    });
  }

  async start(delayTime: number, spinUid: number) {
    this.symbols = [];
    this.moving = true;
    this._spinUid = spinUid;

    for (let i = 0; i < this.symbolCount; i++) {
      const isJackpot =
        !this.game.freeSpinSpinsStarted &&
        this.game.jackpotManager.jackpotResponse?.jackpotSymbols?.some(
          (jackpotSymbol) =>
            jackpotSymbol.x === this.index && SYMBOLS_PER_REEL - 1 - jackpotSymbol.y === i
        );
      this.addSymbol(i, delayTime, isJackpot, spinUid);
      await new Promise((resolve) =>
        setTimeout(
          resolve,
          this.game.finishInstantlyActive
            ? Game.settings.animation.symbolAnimation.finishInstantDelayBetweenTwoSymbol
            : Game.settings.animation.symbolAnimation.delayBetweenTwoSymbol
        )
      );
    }
    this._hasProcessedLandSound = false;
  }

  animateScatterWin() {
    this.symbols.forEach((symbol) => {
      if (symbol.symbolType === this.game.config.scatterSymbol) {
        symbol.animateWin();
      }
    });
  }

  update(ticker: Ticker) {
    this.symbols.forEach((symbol) => {
      symbol.update(ticker);
    });
  }
}
