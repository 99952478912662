import { Spine } from '@pixi/spine-pixi';
import { Game } from '../../../games/game';

export class TransitionManager {
  transitionSpine!: Spine;

  constructor(private game: Game) {}

  handleResize = () => {
    if (!this.transitionSpine) return;
    this.transitionSpine.x = this.game.width / 2;
    this.transitionSpine.y = this.game.height / 2;
  };

  animateTransition(onReady?: () => void, destroy = false) {
    if (!destroy) {
      this.transitionSpine = Spine.from({
        skeleton: `jackpotTransitionData`,
        atlas: `jackpotTransitionAtlas`,
      });
      this.transitionSpine.zIndex = 10000;
      this.transitionSpine.x = this.game.width / 2;
      this.transitionSpine.y = this.game.height / 2;
      this.game.app.stage.addChild(this.transitionSpine);
      this.handleResize();
      this.game.onResize.addEventListener(this.handleResize);
    }

    this.transitionSpine.visible = true;
    const transitionAnimation = this.transitionSpine.state.setAnimation(0, 'animation', false);
    transitionAnimation.mixTime;
    transitionAnimation.listener = {
      complete: () => {
        setTimeout(() => {
          this.transitionSpine.visible = false;
          if (destroy) {
            this.transitionSpine.destroy();
            this.game.onResize.removeEventListener(this.handleResize);
          }
        }, 0);
      },
      event: (entry, event) => {
        if (event.data.name === 'transition_keyframe') {
          setTimeout(() => {
            onReady?.();
          }, 0);
        }
      },
    };

    const animationTime = (transitionAnimation.animation?.duration || 2) * 1000;
    return animationTime;
  }
}
