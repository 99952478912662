import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Dede } from './lib/games/dede';
import GameLoader from './components/gameLoader';
import TestHarness from './components/testHarness';
import { TestHarnessClient } from './components/testHarness/testHarnessClient';
import InfoScreen from './components/infoScreen';
import './lib/debug';
import LogRocket from 'logrocket';
import delay from 'delay';

LogRocket.init('5kgtbt/mmm');

let dede: Dede;

function App() {
  if (!dede) dede = new Dede();
  const [progress, setProgress] = React.useState(0);
  const [gameLoaded, setGameLoaded] = React.useState(false);
  useEffect(() => {
    if (window.location.pathname === '/testHarness') return;
    const initPromise = dede.init();
    dede.load(async (progress) => {
      setProgress(progress);
      if (progress === 1) {
        console.log('Game loading');

        await initPromise;
        dede.mount();
        console.log('Game mounted', dede.sessionId);
        LogRocket.identify(new Date().toISOString(), {
          name: dede.sessionId + '',
          email: '-',
        });
        setGameLoaded(true);
      }
    });

    new TestHarnessClient(dede);
    
    document.fonts.load('10px "ManchoBold"');
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/testharness' element={<TestHarness />} />
        {/* Define other routes here */}
        <Route
          path='/*'
          element={
            <div>
              <div id='gameContainer'></div>
              {!gameLoaded && <GameLoader progress={progress} />}
              <InfoScreen game={dede} />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
