import SoundTrack from '../../../../game/managers/soundManager/soundTrack';
import CachedSettingsManager from '../../../../cachedSettingsManager';

class SoundEffectsTrack extends SoundTrack {
  mount() {
    this.registerSoundEffect('sfxSymExplode', { maxVolume: 0.7 });
    this.registerSoundEffect('sfxSymLand', { debounceTime: 100, maxVolume: 0.7 });
    this.registerSoundEffect('sfxSymHighlight', { maxVolume: 0.7 });
    this.registerSoundEffect('sfxClick', { maxVolume: 0.7 });
    this.registerSoundEffect('sfxBonusBuyOpen', { maxVolume: 0.7 });
    this.registerSoundEffect('sfxBonusBuyConfirm', { maxVolume: 0.7 });
    this.registerSoundEffect('sfxScatterLand', { debounceTime: 100, maxVolume: 0.7 });
    this.registerSoundEffect('sfxScatterWin', { debounceTime: 100, maxVolume: 0.7 });
    this.registerSoundEffect('jackpotMetaLand1', { maxVolume: 0.2 });
    this.registerSoundEffect('jackpotMetaLand2', { maxVolume: 0.2 });
    this.registerSoundEffect('jackpotMetaLand3', { maxVolume: 0.2 });
    this.registerSoundEffect('jackpotTrigger', { maxVolume: 0.7 });
    this.registerSoundEffect('sfxMultiplierLand', { debounceTime: 100, maxVolume: 0.7 });
    this.registerSoundEffect('sfxMultiplierReveal', { debounceTime: 100, maxVolume: 0.7 });
    this.registerSoundEffect('musTumble', { maxVolume: 0.7 });

    this._isMounted = true;

    CachedSettingsManager.addListener('soundControlState', (value) => {
      const { soundEffectsEnabled, gameSoundEnabled } = value as unknown as {
        soundEffectsEnabled: boolean;
        gameSoundEnabled: boolean;
      };
      this.volume = soundEffectsEnabled && gameSoundEnabled ? 1 : 0;
    });

    const { soundEffectsEnabled, gameSoundEnabled } = CachedSettingsManager.get(
      'soundControlState'
    ) as unknown as {
      soundEffectsEnabled: boolean;
      gameSoundEnabled: boolean;
    };
    this.volume = soundEffectsEnabled && gameSoundEnabled ? 1 : 0;
  }

  playTumble() {
    this.getSound('musTumble')!.play();
  }

  playSymExplode() {
    this.getSound('sfxSymExplode')!.play();
  }

  playSymLand() {
    this.getSound('sfxSymLand')!.play();
  }

  playSymHighlight() {
    this.getSound('sfxSymHighlight')!.play();
  }

  playClick() {
    this.getSound('sfxClick')!.play();
  }

  playBonusBuyOpen() {
    this.getSound('sfxBonusBuyOpen')!.play();
  }

  playBonusBuyConfirm() {
    this.getSound('sfxBonusBuyConfirm')!.play();
  }

  playScatterLand() {
    this.getSound('sfxScatterLand')!.play();
  }

  playScatterWin() {
    return new Promise<void>((resolve) => {
      const scatterWin = this.getSound('sfxScatterWin')!;
      scatterWin.play();
      scatterWin.onComplete(() => {
        resolve();
      });
    });
  }

  playJackpotMetaLand(number: 1 | 2 | 3) {
    this.getSound('jackpotMetaLand' + number)!.play();
  }

  playJackpotTrigger() {
    this.getSound('jackpotTrigger')!.play();
  }

  playMultiplierLand() {
    this.getSound('sfxMultiplierLand')!.play();
  }

  playMultiplierReveal() {
    this.getSound('sfxMultiplierReveal')!.play();
  }
}

export default SoundEffectsTrack;
