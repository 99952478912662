import { Ticker } from 'pixi.js';
import { registerLogCategory } from '../../../debug/privateLogger';
import { lerp } from '../../math/interpolationFunctions';

type TAnimationManagerAbortCallback = (options?: {
  forceSuccess?: boolean;
  debugName?: string;
}) => void;
type TAnimationManagerAnimationCallback = (props: {
  delta: number;
  now: number;
  elapsedTime: number;
  removeAnimation: (debugName?: string) => void;
}) => void;

type TAnimationManagerAnimation = {
  callback: TAnimationManagerAnimationCallback;
  startTime: number;
  debugName?: string;
  removeAnimation: (debugName?: string) => void;
};

const log = registerLogCategory('animationManager');

class AnimationManager {
  static _instance: AnimationManager;
  private _ticker!: Ticker;
  private _animations!: Map<TAnimationManagerAnimationCallback, TAnimationManagerAnimation>;

  constructor() {
    if (AnimationManager._instance) return AnimationManager._instance; // Return the existing _instance

    this._ticker = Ticker.shared; // Use the shared PIXI Ticker
    this._animations = new Map(); // Store active _animations
    this._ticker.add(this.update, this); // Attach the update loop

    AnimationManager._instance = this; // Save the _instance
  }

  static getInstance() {
    if (!AnimationManager._instance) {
      new AnimationManager();
    }
    return AnimationManager._instance;
  }

  removeAnimation(callback: TAnimationManagerAnimationCallback, debugName?: string) {
    log(2)(debugName, `AnimationManager remove animation`, {
      matchedAnimation: this._animations.get(callback)?.debugName,
      mapped: Array.from(this._animations.values()).map((animation) => animation.debugName),
    });
    this._animations.delete(callback);
  }

  animate(callback: TAnimationManagerAnimationCallback, debugName?: string) {
    log(2)(debugName, `AnimationManager start animation`, {
      mapped: Array.from(this._animations.values()).map((animation) => animation.debugName),
    });
    const startTime = this._ticker.lastTime;

    const animation = {
      callback,
      startTime,
      debugName,
      removeAnimation: () => this.removeAnimation(callback, debugName),
    };

    this._animations.set(callback, animation);
  }

  update({ deltaTime }: Ticker) {
    log(3)('AnimationManager master tick');
    const now = this._ticker.lastTime;
    Array.from(this._animations.values()).forEach(
      ({ startTime, callback, removeAnimation, debugName }) => {
        const elapsedTime = now - startTime;
        log(4)(debugName, 'AnimationManager tick', { delta: deltaTime, now, elapsedTime });

        callback({ delta: deltaTime, now, elapsedTime, removeAnimation });
      }
    );
  }
}

const simpleContinuousAnimation = (
  callback: TAnimationManagerAnimationCallback,
  debugName?: string
) => {
  log(2)(debugName, 'Start simpleContinuousAnimation');

  AnimationManager.getInstance().animate(({ delta, now, elapsedTime, removeAnimation }) => {
    callback({ delta, now, elapsedTime, removeAnimation });
  }, debugName);

  return () => AnimationManager.getInstance().removeAnimation(callback, debugName);
};

type TSimpleAnimationDurationCallback = (
  props: Parameters<TAnimationManagerAnimationCallback>[0] & { duration: number; progress: number }
) => void;

const simpleAnimationDuration = (
  duration: number,
  callback: TSimpleAnimationDurationCallback,
  debugName?: string
) => {
  log(2)(debugName, 'Start simpleAnimationDuration', { duration });

  let outerRemoveAnimation: TAnimationManagerAbortCallback;
  let lastProgress = 0;

  const promise = new Promise<void>((resolve, reject) => {
    let _removeAnimation: (debugName?: string) => void;
    simpleContinuousAnimation(({ delta, now, elapsedTime, removeAnimation }) => {
      _removeAnimation = removeAnimation;
      const progress = Math.min(elapsedTime / duration, 1);
      lastProgress = progress;

      callback({ delta, now, elapsedTime, duration, progress, removeAnimation });

      if (progress === 1) {
        removeAnimation();
        resolve();
      }
    }, debugName);

    outerRemoveAnimation = ({ forceSuccess = false, debugName } = {}) => {
      if (!_removeAnimation) console.error('No removeAnimation function found');
      else _removeAnimation(debugName);
      (forceSuccess ? resolve : reject)();
    };
  });

  return {
    promise,
    removeAnimation: outerRemoveAnimation!,
  };
};

type TSimpleAnimatePropertiesToFormatter = (value: number) => string;

const simpleAnimatePropertiesTo = <propsToAnimateT extends Record<string, unknown>>(
  duration: number,
  target: { [Key in keyof propsToAnimateT]: unknown },
  startingValues: { [Key in keyof propsToAnimateT]: number },
  propsAnimateTo: {
    [Key in keyof propsToAnimateT]: {
      endValue: number;
      formatter?: TSimpleAnimatePropertiesToFormatter;
    };
  },
  {
    autoEndOnError = false,
    debugName,
  }: {
    autoEndOnError?: boolean;
    debugName?: string;
  } = {}
) => {
  log(2)(debugName, 'Start simpleAnimatePropertiesTo', {
    duration,
    target,
    startingValues,
    propsAnimateTo,
  });

  const _startingValues = {} as Record<keyof propsToAnimateT, number>;

  Object.entries(propsAnimateTo).forEach(([key]) => {
    const typedKey = key as keyof propsToAnimateT;
    _startingValues[typedKey] = startingValues[key];
  });

  const ret = simpleAnimationDuration(
    duration,
    ({ progress }: { progress: number }) => {
      Object.entries(propsAnimateTo).forEach(([key, { endValue, formatter }]) => {
        try {
          const animatedValue = lerp(
            _startingValues[key as keyof propsToAnimateT],
            endValue,
            progress
          );
          (target as Record<string, unknown>)[key] =
            typeof formatter === 'undefined' ? animatedValue : formatter(animatedValue);
        } catch (e) {
          if (autoEndOnError) ret.removeAnimation({ forceSuccess: true });
          else {
            throw e;
          }
        }
      });
    },
    debugName
  );
  return ret;
};

export default AnimationManager;

export { simpleContinuousAnimation, simpleAnimationDuration, simpleAnimatePropertiesTo };
